import React, { FC } from 'react';
import { Modal, useModal } from '../../../dialogs/useModal';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { Upload, Edit as EditIcon } from '@mui/icons-material';
import { getThumbnailUrlFromImageFigure, ImageFigure } from './figureHelper';
import { ConfirmDialog, ConfirmDialogProps } from '../../../dialogs/ConfirmDialog';
import { theme } from '../../../theme';
import { useGuideCmsContext } from '../GuideCmsContext';
import { useConfig } from '../../../env';
import { useFiguresContext } from './FiguresContext';

const StyledFiguresContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
`;

const CardActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  gap: 4px;
  width: 100%;
  align-items: center;
`;

export const GuideFiguresOverview: FC = () => {
  const { figures, createFigure, editFigure, closeModal } = useFiguresContext();
  console.log(figures);
  const handleOnClose = () => {
    closeModal();
  };

  const onUploadClick = () => {
    const figure = createFigure();
    editFigure(figure.figureId);
  };

  return (
    <>
      <DialogTitle>Figurer</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Stack direction="row" gap={2}>
            <Button onClick={onUploadClick} variant="contained" endIcon={<Upload />}>
              Last opp figur
            </Button>
          </Stack>

          <Typography variant="h6">Filer i anvisningen</Typography>
          <StyledFiguresContainer>
            {figures.map((figure) => (
              <FigureOverviewCard key={figure.figureId} figure={figure} />
            ))}
          </StyledFiguresContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          Lukk
        </Button>
      </DialogActions>
    </>
  );
};

const FigureOverviewCard: FC<{
  figure: ImageFigure;
}> = ({ figure }) => {
  const config = useConfig();
  const { guideId, editor } = useGuideCmsContext();
  const { editFigure, insertFigure } = useFiguresContext();

  const deleteConfirmModal = useModal<ConfirmDialogProps, boolean>({
    data: { title: 'Slett figur', message: 'Er du sikker på at du vil slette denne figuren?', submitText: 'Slett' },
  });

  const thumbnailUrl = getThumbnailUrlFromImageFigure(config, guideId, figure) ?? 'https://placehold.co/500x300?text=Mangler+bilde';
  const fileName = figure.files?.[0]?.fileName ?? '';
  const isDisabled = !editor?.isEditable || !editor.can().chain().focus().insertImageFigure().run();
  return (
    <Card
      key={figure.figureId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <CardMedia sx={{ height: 140 }} image={thumbnailUrl} />
      <CardContent sx={{ padding: theme.spacing(1, 1.5), flexGrow: 1 }}>
        <Typography variant="body2" color="textSecondary">
          {fileName}
        </Typography>
      </CardContent>
      <CardActions>
        <CardActionsContainer>
          <div>
            <div>
              <Button disabled={isDisabled} size="small" onClick={() => insertFigure(figure.figureId)}>
                Sett inn
              </Button>
            </div>
          </div>

          <IconButton size="small" onClick={() => editFigure(figure.figureId)} title="Rediger">
            <EditIcon />
          </IconButton>
        </CardActionsContainer>
      </CardActions>
      {deleteConfirmModal.isOpen && <ConfirmDialog modal={deleteConfirmModal} />}
    </Card>
  );
};
