import { useEffect, useRef, useState } from 'react';
import { useConfig } from '../../../env';
import { TiptapCollabProvider, WebSocketStatus } from '@hocuspocus/provider';

import { useAuth } from 'oidc-react';
import { CollaborationUser, createUserFromProfile } from './collaborationUser';
import { useCmsCollaborationApi } from '../useCmsCollaborationApi';
import { useGuideCmsContext } from '../GuideCmsContext';

export interface ConnectionStatus {
  isConnected: boolean;
  isConnecting: boolean;
}

export interface SaveStatus {
  isSaving: boolean;
  invalidSchemaError: boolean;
  error?: string;
  savedAt?: string;
}

export interface TiptapCollabResponse {
  provider?: TiptapCollabProvider;
  connectionStatus: ConnectionStatus;
  connectedUsers: CollaborationUser[];
}

export const useTiptapCollab = (): TiptapCollabResponse => {
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(true);
  const [connectedUsers, setConnectedUsers] = useState<CollaborationUser[]>([]);
  const { guide } = useGuideCmsContext();
  const config = useConfig();
  const auth = useAuth();
  const user = createUserFromProfile(auth.userData?.profile);
  const { authenticate } = useCmsCollaborationApi();

  const providerRef = useRef<TiptapCollabProvider | null>(null);

  useEffect(() => {
    async function configureProvider() {
      if (!auth.userData?.access_token) {
        console.log('Missing auth token. Will not connect to Tiptap Collab');
        return;
      }
      if (!config) {
        return;
      }
      if (!guide?.tiptapDocument) {
        return;
      }

      const authenticationResult = await authenticate();
      console.log(`Connected to Tiptap Cloud for Guide ${guide?.docName}. Document name: ${guide?.tiptapDocument?.documentName}`);
      const provider = new TiptapCollabProvider({
        appId: config.tiptapCloudAppId,
        name: `${guide.tiptapDocument?.documentName}`,
        token: authenticationResult.token,
        user: user.name,
        onStatus(data) {
          if (data.status === WebSocketStatus.Connecting) {
            setIsConnecting(true);
            setIsConnected(false);
          }
        },
        onSynced(data) {
          setIsConnecting(false);
          setIsConnected(true);
        },
        onDisconnect() {
          setIsConnecting(false);
          setIsConnected(false);
        },
        onAuthenticationFailed(data) {
          setIsConnecting(false);
          setIsConnected(false);
        },
        onAwarenessUpdate(data) {
          const users = data.states.map((state) => state.user as CollaborationUser);
          setConnectedUsers(users);
        },
      });

      providerRef.current = provider;
    }
    configureProvider();

    return () => {
      // Close connections to Tiptap Collab when component is unmounted.
      // NOTE: If React.StrictMode is used, this will break as cleanup function will be called twice.
      if (providerRef.current) {
        console.log('Disconnecting from Tiptap Collab');
        providerRef.current.destroy();
        providerRef.current = null;
      }
    };
  }, [auth.userData?.access_token, config, guide]);
  const connectionStatus: ConnectionStatus = {
    isConnected,
    isConnecting,
  };

  return {
    provider: providerRef.current ?? undefined,
    connectionStatus,
    connectedUsers,
  };
};
