import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../../theme';
import {
  Avatar,
  Button,
  Paper,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Divider,
  Alert,
  Box,
  Badge,
} from '@mui/material';
import { Cancel, CheckCircle, Delete, Edit, Forum, LockOpen, Save, Send, Sms } from '@mui/icons-material';
import { SidebarHeading } from '../SidebarHeading';
import { CommentType, ThreadType, useThreadsState } from './context';
import { formatDate } from '../../../../Formatters';
import moment from 'moment';

const StyledAvatar = styled(Avatar)`
  height: 28px;
  width: 28px;
  font-size: 14px;
  margin-top: -4px;
  margin-bottom: -4px;
`;

const DrawerContent = styled.div`
  padding: ${theme.spacing(2)};
`;

export const Threads: FC = () => {
  const { threads, showUnresolved, setShowUnresolved } = useThreadsState();
  const numberOfOpenThreads = useMemo(() => threads.filter((thread) => !thread.resolvedAt).length, [threads]);
  const numberOfClosedThreads = useMemo(() => threads.filter((thread) => thread.resolvedAt).length, [threads]);
  const filteredThreads = useMemo(() => {
    return threads.filter((thread) => (showUnresolved ? !thread.resolvedAt : thread.resolvedAt));
  }, [threads, showUnresolved]);
  if (!threads) {
    return <div>Loading...</div>;
  }
  return (
    <DrawerContent>
      <SidebarHeading icon={<Forum />}>Kommentarer</SidebarHeading>
      <ToggleButtonGroup
        color="primary"
        value={showUnresolved ? 'open' : 'resolved'}
        size="small"
        exclusive
        onChange={(_e, newTab) => {
          setShowUnresolved(newTab === 'open');
        }}>
        <ToggleButton value="open">Åpne ({numberOfOpenThreads})</ToggleButton>
        <ToggleButton value="resolved">Lukkede ({numberOfClosedThreads})</ToggleButton>
      </ToggleButtonGroup>
      {filteredThreads.length === 0 && <Typography sx={{ mt: 2 }}>Ingen {showUnresolved ? 'åpne' : 'lukkede'} kommentarer</Typography>}
      {filteredThreads.map((thread) => (
        <Thread key={thread.id} thread={thread} />
      ))}
    </DrawerContent>
  );
};

const StyledThread = styled(Paper)<{ isSelected: boolean }>`
  margin-top: ${theme.spacing(1)};
  padding: ${theme.spacing(1)};
`;

const StyledThreadHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${theme.spacing(2)};
  align-items: center;
`;

const Thread: FC<{ thread: ThreadType }> = ({ thread }) => {
  const { selectedThread, onHoverThread, onLeaveThread, resolveThread, unresolveThread, deleteThread, onClickThread, createComment } =
    useThreadsState();
  const isSelected = selectedThread === thread.id;
  const [replyContent, setReplyContent] = React.useState('');
  const handleCreateComment = () => {
    createComment(thread.id, replyContent);
    setReplyContent('');
  };

  return (
    <StyledThread
      key={thread.id}
      id={'thread-' + thread.id}
      elevation={isSelected ? 5 : 1}
      isSelected={isSelected}
      onClick={() => onClickThread(thread.id, { origin: 'sidebar' })}
      onMouseEnter={() => onHoverThread(thread.id)}
      onMouseLeave={() => onLeaveThread()}>
      {thread.resolvedAt && (
        <Alert
          action={
            <Button
              size="small"
              color="success"
              variant="contained"
              startIcon={<LockOpen fontSize="small" />}
              onClick={() => unresolveThread(thread.id)}>
              Gjenåpne
            </Button>
          }>
          Lukket {formatDate(thread.resolvedAt)}
        </Alert>
      )}
      <StyledThreadHeader>
        <div>
          <Badge badgeContent={thread.comments.length} color="info" sx={{ ml: 1, mt: 1 }}>
            <Sms color="action" />
          </Badge>
        </div>

        <div>
          {!thread.resolvedAt && (
            <Button color="success" size="small" startIcon={<CheckCircle fontSize="small" />} onClick={() => resolveThread(thread.id)}>
              Lukk
            </Button>
          )}
          <Button color="error" size="small" startIcon={<Delete fontSize="small" />} onClick={() => deleteThread(thread.id)}>
            Slett
          </Button>
        </div>
      </StyledThreadHeader>
      <div>
        <Comment key={thread.comments[0].id} thread={thread} comment={thread.comments[0]} disableDelete={true} />
        {thread.comments.length > 1 && <Divider />}
      </div>
      <div>
        {thread.comments.slice(1).map((comment, i) => {
          return (
            <React.Fragment key={comment.id}>
              <Comment key={comment.id} thread={thread} comment={comment} />
              {i < thread.comments.length - 2 && <Divider />}
            </React.Fragment>
          );
        })}
        {isSelected && !thread.resolvedAt && (
          <>
            <Divider sx={{ mb: 1 }} />
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <TextField
                size="small"
                multiline
                sx={{ width: '100%' }}
                placeholder="Ny kommentar..."
                value={replyContent}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleCreateComment();
                  }
                }}
                onChange={(e) => setReplyContent(e.target.value)}></TextField>
              <IconButton color="primary" disabled={!replyContent} onClick={handleCreateComment}>
                <Send />
              </IconButton>
            </Stack>
          </>
        )}
      </div>
    </StyledThread>
  );
};

const StyledComment = styled.div`
  padding: ${theme.spacing(1)};
`;

const StyledCommentHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: ${theme.spacing(2)};
  align-items: center;
`;

const Comment: FC<{ thread: ThreadType; comment: CommentType; disableDelete?: boolean }> = ({ thread, comment, disableDelete = false }) => {
  const { removeComment, updateComment, canUpdateComment, selectedThread } = useThreadsState();
  const isSelected = selectedThread === thread.id;
  const isEdtiable = canUpdateComment(comment) && isSelected && !thread.resolvedAt;
  const isDeletable = canUpdateComment(comment) && isSelected && !thread.resolvedAt && !disableDelete;

  const [content, setContent] = React.useState(comment.content);
  const [isEditing, setIsEditing] = React.useState(false);
  return (
    <StyledComment>
      <Stack spacing={1}>
        <StyledCommentHeader>
          <StyledAvatar sx={{ backgroundColor: comment.data.user.color }}>{comment.data.user.initials}</StyledAvatar>{' '}
          <Stack>
            <Typography variant="subtitle2">{comment.data.user.name}</Typography>
            <Typography variant="caption">{moment(comment.updatedAt).startOf('seconds').fromNow()}</Typography>
          </Stack>
          {!isEditing && (
            <Stack direction="row" spacing={1}>
              {isSelected && (
                <Stack direction="row" alignItems="center">
                  {isEdtiable && (
                    <div>
                      <IconButton title="Rediger kommentar" size="small" onClick={() => setIsEditing(!isEditing)}>
                        <Edit fontSize="small" />
                      </IconButton>
                    </div>
                  )}
                  {isDeletable && canUpdateComment(comment) && (
                    <div>
                      <IconButton title="Slett kommentar" size="small" onClick={() => removeComment(thread.id, comment.id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </div>
                  )}
                </Stack>
              )}
            </Stack>
          )}
          {isEditing && (
            <Stack direction="row" spacing={1}>
              <Stack direction="row" alignItems="center">
                <div>
                  <IconButton
                    title="Lagre"
                    size="small"
                    onClick={() => {
                      updateComment(thread.id, comment.id, content);
                      setIsEditing(false);
                    }}>
                    <Save fontSize="small" />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    title="Avbryt"
                    size="small"
                    onClick={() => {
                      setContent(comment.content);
                      setIsEditing(false);
                    }}>
                    <Cancel fontSize="small" />
                  </IconButton>
                </div>
              </Stack>
            </Stack>
          )}
        </StyledCommentHeader>
        {!isEditing && <Box sx={{ width: '100%', whiteSpace: 'pre-line' }}>{comment.content}</Box>}
        {isEditing && (
          <TextField
            value={content}
            size="small"
            multiline
            sx={{ width: '100%' }}
            onClick={() => setIsEditing(true)}
            onChange={(e) => setContent(e.target.value)}
          />
        )}
      </Stack>
    </StyledComment>
  );
};
