import { useEditor } from '@tiptap/react';
import { Editor } from '@tiptap/core';
import { useCallback } from 'react';
import { useTiptapCollab, TiptapCollabResponse } from '../tiptapCollab/useTiptapCollab';
import { useAuth } from 'oidc-react';
import { getExtensions, getExtensionsForPreview } from './extensions/plugins';
import { ImageFigureView } from './extensions/views/ImageFigureView';
import { getGuideLinkExtensionRenderOptions } from './extensions/views/GuideLinkView';
import { getInternalLinkExtensionRenderOptions } from './extensions/views/InternalLinkView';
import { TableFigureView } from './extensions/views/TableFigureView';
import { useUpdateNumberedNodesOnEditor } from './numbering';
import { HeadingView } from './extensions/views/HeadingView';
import { debounce } from 'throttle-debounce';
import { AppView } from './extensions/views/AppView';
import { ChartFigureView } from './extensions/views/ChartFigureView';
import { ChartView } from './extensions/views/ChartView';
import { MathTypeView } from './extensions/views/MathTypeView';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import { getStandardLinkExtensionRenderOptions } from './extensions/views/StandardLinkView';
import { handlePasteTransaction } from './pasting';
import { useGuideCmsContext } from '../GuideCmsContext';
import { useThreadsState } from '../sidebar/tiptapComments/context';

interface GuideEditorProps {
  onEditorCreated?: (editor: Editor, provider?: TiptapCollabProvider) => void;
  isEditable: boolean;
}

const getRenderOptions = () => {
  const guideLinkExtensionRenderOptions = getGuideLinkExtensionRenderOptions();
  const standardLinkExtensionRenderOptions = getStandardLinkExtensionRenderOptions();
  const internalLinkExtensionRenderOptions = getInternalLinkExtensionRenderOptions();
  return {
    appView: AppView,
    imageFigureView: ImageFigureView,
    tableFigureView: TableFigureView,
    headingView: HeadingView,
    guideLink: guideLinkExtensionRenderOptions,
    standardLink: standardLinkExtensionRenderOptions,
    internalLink: internalLinkExtensionRenderOptions,
    chartFigureView: ChartFigureView,
    chartView: ChartView,
    mathTypeView: MathTypeView,
  };
};

export const usePreviewGuideEditor = () => {
  const { provider } = useGuideCmsContext();
  const extensions = getExtensionsForPreview({ rendering: getRenderOptions(), provider });
  const editor = useEditor({
    editable: false,
    extensions,
  });
  return {
    editor,
  };
};

export const useGuideEditor = ({ onEditorCreated, isEditable }: GuideEditorProps): { editor: Editor | null; tiptapCollab: TiptapCollabResponse } => {
  const auth = useAuth();
  const profile = auth.userData?.profile;
  const tiptapCollab = useTiptapCollab();
  const { onClickThread } = useThreadsState();
  const extensions = getExtensions({ rendering: getRenderOptions(), profile, provider: tiptapCollab.provider, comments: { onClickThread } });
  const updateNumberedNodesOnEditor = useUpdateNumberedNodesOnEditor();
  const { forceRefreshEditors, setIsValidSchema } = useGuideCmsContext();

  const updatedTocAndNumberingDebounced = useCallback(
    debounce(300, (editor: Editor) => {
      setTimeout(() => {
        updateNumberedNodesOnEditor(editor);
        forceRefreshEditors();
      });
    }),
    [],
  );

  const editor = useEditor(
    {
      editable: isEditable,
      extensions,
      onCreate(props) {
        updateNumberedNodesOnEditor(props.editor);
        onEditorCreated?.(props.editor, tiptapCollab.provider);
      },
      onUpdate(props) {
        updatedTocAndNumberingDebounced(props.editor);
      },
      onDestroy() {},
      onTransaction(props) {
        handlePasteTransaction(props.editor, props.transaction);
      },
      enableContentCheck: true,
      onContentError(props) {
        console.log('Content error', props.error);
        props.disableCollaboration();
        props.editor.setEditable(false, false);
        setIsValidSchema(false);
      },
    },
    [tiptapCollab.provider, isEditable],
  );
  return {
    editor,
    tiptapCollab,
  };
};
