import React, { FC } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { UploadGuideFigureFiles } from './UploadGuideFigureFiles';
import useKeyDown from '../../../utils/useKeyDown';
import { useFiguresContext } from './FiguresContext';

export const EditGuideFigure: FC = () => {
  const { isEditing, isEditingInitially, closeEdit, closeModal } = useFiguresContext();

  useKeyDown('Escape', async () => {
    closeModal();
  });

  const handleOnClose = async () => {
    if (isEditingInitially) {
      closeModal();
    } else {
      closeEdit();
    }
  };

  return (
    <>
      <DialogTitle>{isEditing ? 'Rediger figur' : 'Last opp figur'}</DialogTitle>
      <DialogContent>
        <UploadGuideFigureFiles />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          Lukk
        </Button>
      </DialogActions>
    </>
  );
};
