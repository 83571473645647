import React, { FC } from 'react';
import { Comment } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { AddCommentDialog, AddCommentDialogProps, AddCommentDialogResult } from '../../../dialogs/AddCommentDialog';

export const AddCommentToolbarButton: FC = () => {
  const editor = useRichTextEditorContext();
  const modal = useModal<AddCommentDialogProps, AddCommentDialogResult>({ data: {} });
  const handleOnClick = async () => {
    modal.open();
  };
  if (!editor) return null;
  return (
    <>
      {modal.isOpen && <AddCommentDialog {...modal} />}
      <MenuButton disabled={!editor?.isEditable} IconComponent={Comment} tooltipLabel="Kommentar" onClick={handleOnClick}></MenuButton>
    </>
  );
};
