import 'katex/dist/katex.min.css';
import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { AddGuideLinkToolbarButton } from './extensions/toolbar/AddGuideLinkToolbarButton';
import {
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonUnderline,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuSelectTextAlign,
  LinkBubbleMenu,
  MenuButtonEditLink,
  MenuButtonSuperscript,
  MenuButtonSubscript,
  RichTextField,
  RichTextEditorProvider,
  MenuButtonUndo,
  MenuButtonRedo,
  MenuButtonHighlightToggle,
} from 'mui-tiptap';
import styled from '@emotion/styled';
import { MathematicsStyles } from './extensions/Mathematics';
import { AiToolbarButton } from './extensions/toolbar/AiToolbarButton';
import { FigureToolbarButton } from './extensions/toolbar/FigureToolbarButton';
import { Public } from '@mui/icons-material';
import { AddInternalLinkToolbarButton } from './extensions/toolbar/AddInternalLinkToolbarButton';
import AddTableToolbarButton from './extensions/toolbar/AddTableToolbarButton';
import TableBubbleMenu from './extensions/TableBubbleMenu';
import { AddAppToolbarButton } from './extensions/toolbar/AddAppToolbarButton';
import { AddChartToolbarButton } from './extensions/toolbar/AddChartToolbarButton';
import { FormulaToolbarButton } from './extensions/toolbar/FormulaToolbarButton';
import { SymbolsToolbarButton } from './extensions/toolbar/SymbolsToolbarButton';
import { AddStandardLinkToolbarButton } from './extensions/toolbar/AddStandardLinkToolbarButton';
import { TextBubbleMenu } from './extensions/TextBubbleMenu';
import MenuSelectOrderedLists from './extensions/toolbar/MenuSelectOrderedLists';
import { useThreadsState } from '../sidebar/tiptapComments/context';
import { css } from '@emotion/react';
import { useGuideCmsContext } from '../GuideCmsContext';
import { theme } from '../../../theme';

const StyledEditor = styled.div<{ numberOfColumns: number; showThreads: boolean; showUnresolvedThreads: boolean }>`
  .tiptap.ProseMirror {
    --comment: rgba(250, 204, 21, 0.4);
    --comment-contrast: #facc15;
    --link-color: #c2ecff;
    --link-standard: var(--link-color);
    --link-guide: var(--link-color);
    --link-internal-heading: var(--link-color);
    --link-internal-imageFigure: var(--link-color);
    --link-internal-chartFigure: var(--link-color);
    --link-internal-tableFigure: var(--link-color);
    --link-internal-app: var(--link-color);

    --diff-insert: #e2ffe8;
    --diff-delete: #ffe2e2;

    padding-left: ${(props) => props.numberOfColumns * 35 + 8}px;
    padding-right: 8px;
    font-size: 16px;
    ${MathematicsStyles}
    figure {
      margin: 16px 0;
    }
    h1,
    h2,
    h3 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    p {
      margin-bottom: 7px;
      line-height: 1.3;
    }
    ul,
    ol {
      padding-left: 18px;
    }
    ol[type='I'] {
      list-style-type: upper-roman;
    }
    ol[type='1'] {
      list-style-type: decimal;
    }
    ol[type='a'] {
      list-style-type: lower-alpha;
    }
    .link {
      padding: 0px 1px;
    }
    .link-icon > svg {
      font-size: 1.25rem;
      vertical-align: text-bottom;
      color: ${theme.palette.text.secondary};
    }
    .link-standard {
      background-color: var(--link-standard);
    }
    .link-guide {
      background-color: var(--link-guide);
    }
    .link-internal-heading {
      background-color: var(--link-internal-heading);
      cursor: pointer;
    }
    .link-internal-imageFigure,
    .link-internal-chartFigure {
      background-color: var(--link-internal-imageFigure);
    }
    .link-internal-tableFigure {
      background-color: var(--link-internal-tableFigure);
    }
    .link-internal-app {
      background-color: var(--link-internal-app);
    }

    [data-diff-type='inline-insert'],
    [data-diff-type='inline-delete'],
    [data-diff-type='block-insert'],
    [data-diff-type='block-delete'] {
      &[data-diff-user-id]:not([data-diff-user-id='']) {
        cursor: pointer;
        position: relative;
        display: inline-block;
        &:hover:before {
          visibility: visible;
        }
        &:before {
          content: attr(data-diff-user-id);
          font-weight: 400;
          line-height: normal;
          visibility: hidden;
          background-color: #fff;
          border: 1px solid #000;
          color: #000;
          text-align: center;
          border-radius: 6px;
          font-size: 14px;
          padding: 3px;
          position: absolute;
          z-index: 1;
          top: 24px;
          left: 5px;
          white-space: nowrap;
        }
      }
      position: relative;
      &:has(.link) {
        * {
          background-color: inherit;
        }
      }
      &:has(.node-tableFigure),
      &:has(p) {
        display: block !important;
      }
    }

    [data-diff-type='inline-insert'],
    [data-diff-type='block-insert'] {
      color: #000;
      background-color: var(--diff-insert);
    }

    [data-diff-type='inline-delete'],
    [data-diff-type='block-delete'] {
      color: #000;
      background-color: var(--diff-delete);
      text-decoration: line-through;
    }

    // Special handling of tables
    // Hide diff marks on nested nodes
    tbody > div.diff,
    tr > div.diff {
      display: contents;
    }

    ${({ showThreads, showUnresolvedThreads }) =>
      showThreads &&
      css`
        /* Thread mark */
        .tiptap-thread,
        .tiptap-thread * {
          transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
        }
        .tiptap-thread[data-type='block'] {
          &:first-child {
            margin-top: 0;
          }

          > :not(p, h1, h2, h3, h4, h5, h6, ul, li) {
            outline-width: 2px;
            outline-color: transparent;
            outline-style: dashed;
          }
        }
        ${showUnresolvedThreads ? '.tiptap-thread[data-status-all="open"]' : '.tiptap-thread[data-status-all="closed"]'} {
           &[data-type='block'] {
        > :not(p, h1, h2, h3, h4, h5, h6, ul, li) {
          background-color: var(--comment);
          outline-color: var(--comment);
        }

        &:hover,
        &[data-state='selected'],
        &[data-state='hovered'] {
          > :not(p, h1, h2, h3, h4, h5, h6, ul, li) {
            background-color: var(--comment-contrast);
            outline-color: var(--comment-contrast);
          }
        }
      }

      &[data-type='inline'] {
        border-bottom: 2px dashed var(--comment-contrast);

        &:hover,
        &[data-state='selected'],
        &[data-state='hovered'] {
          background-color: var(--comment);
        }
      }
      `};

    // Hide block node actions on nested nodes
    *[class*='node-'] {
      *[class*='node-'] {
        .block-node-actions {
          display: none;
        }
        .block-node-content {
          margin-left: 0;
        }
      }
    }
  }
`;

export const GuideEditor: FC<{ guideId: string; editor: Editor; menuComponent?: React.ReactNode }> = ({ guideId, editor, menuComponent }) => {
  const { isSidebarOpen, sidebarType } = useGuideCmsContext();
  const { showUnresolved } = useThreadsState();
  const showThreads = isSidebarOpen && sidebarType === 'comments' && editor.isEditable;

  return (
    <StyledEditor numberOfColumns={0} showUnresolvedThreads={showUnresolved} showThreads={showThreads}>
      <RichTextEditorProvider editor={editor}>
        <RichTextField
          variant={editor?.isEditable ? 'outlined' : 'standard'}
          MenuBarProps={{ stickyOffset: 64 }}
          controls={
            editor?.isEditable && (
              <MenuControlsContainer>
                {menuComponent && <div style={{ width: '100%' }}>{menuComponent}</div>}
                <MenuButtonUndo />
                <MenuButtonRedo />
                <MenuDivider />
                <MenuSelectHeading />
                <MenuDivider />
                <MenuButtonBold />
                <MenuButtonItalic />
                <MenuButtonUnderline />
                <MenuButtonHighlightToggle />
                <MenuDivider />
                <MenuButtonSuperscript />
                <MenuButtonSubscript />
                <FormulaToolbarButton />
                <SymbolsToolbarButton />
                <MenuDivider />
                <MenuSelectTextAlign />
                <MenuButtonBulletedList />
                <MenuButtonOrderedList />
                <MenuSelectOrderedLists />
                <MenuDivider />
                <MenuButtonEditLink IconComponent={Public} tooltipLabel="Ekstern lenke" />
                <AddStandardLinkToolbarButton />
                <AddGuideLinkToolbarButton guideId={guideId} />
                <AddInternalLinkToolbarButton />
                <MenuDivider />
                <FigureToolbarButton />
                <AddAppToolbarButton />
                <AddChartToolbarButton />
                <AddTableToolbarButton />
                <MenuDivider />
                <AiToolbarButton />
              </MenuControlsContainer>
            )
          }></RichTextField>

        <TableBubbleMenu placement="top-start" fallbackPlacements={['bottom-start']} />
        <LinkBubbleMenu />
        <TextBubbleMenu guideId={guideId} />
      </RichTextEditorProvider>
    </StyledEditor>
  );
};
