import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GUIDE_CMS_INFO } from './content.graphql';
import { Editor } from '@tiptap/react';
import { GuideCmsInfo } from './types';
import { TiptapCollabProvider } from '@hocuspocus/provider';

type SidebarType = 'toc' | 'comments' | 'help' | 'references';

export interface GuideCmsContext {
  isLoading: boolean;
  guideId: string;
  isValidSchema: boolean;
  setIsValidSchema: (isValidSchema: boolean) => void;
  guide: GuideCmsInfo;
  editor: Editor | null;
  provider?: TiptapCollabProvider;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  sidebarType: SidebarType;
  setSidebarType: (sidebarType: SidebarType) => void;
  setEditor: (editor: Editor) => void;
  setProvider: (provider: TiptapCollabProvider) => void;
  forceRefreshEditors: () => void;
}

const GuideCmsContextInstance = React.createContext<GuideCmsContext | undefined>(undefined);

export const useGuideCmsContext = (): GuideCmsContext => {
  const context = React.useContext(GuideCmsContextInstance);
  if (!context) {
    throw new Error('useGuideCmsContext must be used within a GuideCmsContextProvider');
  }
  return context;
};

export const GuideCmsContextProvider: FC<{ guideId: string; version?: number; children: React.ReactNode }> = ({ guideId, version, children }) => {
  const [guide, setGuide] = useState<GuideCmsInfo>();
  const [editor, setEditor] = useState<Editor | null>(null);
  const [provider, setProvider] = useState<TiptapCollabProvider>();
  const [sidebarType, setSidebarType] = useState<SidebarType>('toc');
  const [isValidSchema, setIsValidSchema] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [_, setForceRefreshCount] = useState<number>(0);

  useEffect(() => {
    setGuide(undefined);
    guideContentQuery();
  }, [guideId]);

  useEffect(() => {
    if (!version) {
      setGuide(undefined);
      guideContentQuery();
    }
  }, [version]);

  const [guideContentQuery, guideContentResponse] = useLazyQuery(GUIDE_CMS_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: { id: guideId },
    onCompleted(data) {
      setGuide(data?.guide);
    },
  });

  const forceRefreshEditors = () => {
    setForceRefreshCount((prev) => prev + 1);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <GuideCmsContextInstance.Provider
      value={{
        isLoading: guideContentResponse.loading,
        guideId,
        guide,
        isValidSchema,
        setIsValidSchema,
        editor,
        provider,
        sidebarType,
        isSidebarOpen,
        toggleSidebar,
        setSidebarType,
        setEditor,
        setProvider,
        forceRefreshEditors,
      }}>
      {children}
    </GuideCmsContextInstance.Provider>
  );
};
