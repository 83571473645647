import React, { FC } from 'react';
import { StyledPaper, theme } from '../theme';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { BackButton } from '../Components';
import { GET_GUIDE_HEARINGS, Hearing, HearingParticipant, SEND_INVITE, SUBSCRIBE_HEARING_UPDATED } from './hearing.graphql';
import { formatDate } from '../Formatters';
import { CheckCircle, ExpandMore, Send } from '@mui/icons-material';
import { useFeatureFlags } from '../unleash';
import { HearingDescription, HearingInfo } from './HearingInfo';

export const HearingsPage: FC = () => {
  const { id } = useParams();
  const { guideCms } = useFeatureFlags();

  const guideId = id ?? '';
  const { loading, data } = useQuery(GET_GUIDE_HEARINGS, { variables: { guideId } });
  const hearings = data?.guide?.hearings ?? [];
  const guide = data?.guide;
  const historicalHearings = hearings.filter((hearing) => !!hearing?.endedAt);
  const currentHearing = hearings.find((hearing) => !hearing?.endedAt);
  if (loading) return <LoadingSpinner />;
  if (!guide || !guideCms) return null;
  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        <BackButton />
        Høringer for {guide.docName} {guide.docTitle}
      </Typography>
      <Stack gap={2}>
        <StyledPaper>
          {currentHearing && <SubscribeToHearingUpdates hearingId={currentHearing.id} />}
          <HearingInfo hearing={currentHearing ?? undefined} guideId={guideId} />

          {currentHearing?.startedAt && <HearingDetails hearing={currentHearing} />}
        </StyledPaper>

        {historicalHearings.length > 0 && (
          <Typography sx={{ marginTop: theme.spacing(4) }} variant="h5" component="h3">
            Tidligere høringer
          </Typography>
        )}

        <div>
          {historicalHearings.map((hearing) => {
            if (!hearing) return null;
            return (
              <Accordion key={hearing.id}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <HearingDescription hearing={hearing} />
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <HearingDetails hearing={hearing} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </Stack>
    </StyledPaper>
  );
};

const SubscribeToHearingUpdates: FC<{ hearingId: string }> = ({ hearingId }) => {
  useSubscription(SUBSCRIBE_HEARING_UPDATED, { variables: { hearingId } });
  return null;
};

const HearingDetails: FC<{ hearing: Hearing }> = ({ hearing }) => {
  return (
    <>
      <TableContainer>
        <Table sx={{ width: 'auto', minWidth: '700px', tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Svar</TableCell>
              <TableCell>Invitasjon</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hearing.participants?.map((participant) => {
              if (!participant) return null;
              return <HearingParticipantDetails key={participant.id} hearing={hearing} participant={participant} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const HearingParticipantDetails: FC<{ hearing: Hearing; participant: HearingParticipant }> = ({ hearing, participant }) => {
  const [sendInvite, { loading: isSendingInvite }] = useMutation(SEND_INVITE);
  const isActive = !hearing.endedAt;

  const onSendInvite = async (participantId: string) => {
    await sendInvite({ variables: { input: { id: hearing.id, participantId } } });
  };

  return (
    <TableRow key={participant.id}>
      <TableCell sx={{ padding: '0 10px', paddingTop: '5px' }}>
        {participant.isFinished && (
          <Tooltip title="Deltakeren er ferdig med å kommentere">
            <CheckCircle color="success" />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{participant.email}</TableCell>
      <TableCell>{participant.contentComments?.length ?? 0}</TableCell>

      <TableCell>
        <Stack direction="row" gap={2} alignItems="center">
          {participant.invitationSentAt && <>E-post sendt {formatDate(participant.invitationSentAt)}</>}
        </Stack>
      </TableCell>
      <TableCell align="right" sx={{ padding: '0 10px' }}>
        {isActive && (
          <Button startIcon={<Send />} variant="outlined" disabled={isSendingInvite} onClick={() => onSendInvite(participant.id)}>
            Send invitasjon på nytt
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
