import { Editor } from '@tiptap/core';
import { JSONContent } from '@tiptap/react';
import { capitalizeFirstLetter } from '../../../utils/string';
import { InternalLinkFormat } from './extensions/plugins/InternalLink';
import { useGuideCmsContext, GuideCmsContext } from '../GuideCmsContext';

export type NumberedNodes = Map<string, NumberedNode>;

export type NumberedNodeType = 'heading' | 'imageFigure' | 'tableFigure' | 'chartFigure' | 'app';

export interface NumberedNode {
  type: NumberedNodeType;
  id: string;
  numbering: string;
  h1Level: number;
  h2Level: number;
  h3Level: number;
}

const getNumbering = (h1: number, h2: number, h3: number) => {
  if (h3 > 0) {
    if (h3 > 9) {
      const multiplumsBy10 = Math.floor(h3 / 10);
      return `${h1}${h2 + multiplumsBy10}${h3 - multiplumsBy10 * 10}`;
    }
    return `${h1}${h2}${h3}`;
  }
  if (h2 > 0) {
    return `${h1}${h2}`;
  }
  return `${h1}`;
};

const getLetterFromNumber = (number: number) => String.fromCharCode(97 + number);

export const getFullDescription = (node: NumberedNode) => {
  const typeDescription = getTypeDescription(node.type);
  return `${capitalizeFirstLetter(typeDescription)} ${node.numbering}`;
};
const figureTypes: NumberedNodeType[] = ['imageFigure', 'chartFigure'];
const isSameLogicalType = (typeA: NumberedNodeType, typeB: NumberedNodeType) =>
  typeA === typeB || (figureTypes.includes(typeA) && figureTypes.includes(typeB));

export const getTypeDescription = (type: NumberedNodeType, format: InternalLinkFormat = InternalLinkFormat.Default) => {
  switch (type) {
    case 'heading':
      if (format === InternalLinkFormat.CitationIEEEStyle) {
        return 'ref.';
      }
      return 'pkt.';
    case 'imageFigure':
    case 'chartFigure':
      return 'fig.';
    case 'tableFigure':
      return 'tabell';
    case 'app':
      return 'app.';
    default:
      return type;
  }
};

export const getLongTypeDescription = (type: NumberedNodeType, format: InternalLinkFormat = InternalLinkFormat.Default) => {
  switch (type) {
    case 'heading':
      if (format === InternalLinkFormat.CitationIEEEStyle) {
        return 'Referanse';
      }
      return 'Punkt';
    case 'imageFigure':
    case 'chartFigure':
      return 'Figur';
    case 'tableFigure':
      return 'Tabell';
    case 'app':
      return 'App.';
    default:
      return type;
  }
};

export const useNumberedNode = (id: string, editor?: Editor): NumberedNode | undefined => {
  const { editor: editorFromContext } = useGuideCmsContext(); // Hack to make sure nodes are recalculated when the numbering changes
  return ((editor ?? editorFromContext)?.storage['numberedNodes'] as NumberedNodes | undefined)?.get(id);
};

export const useNumberedNodes = (editor?: Editor): NumberedNodes => {
  const { editor: editorFromContext } = useGuideCmsContext(); // Hack to make sure nodes are recalculated when the numbering changes
  return ((editor ?? editorFromContext)?.storage['numberedNodes'] as NumberedNodes | undefined) ?? new Map<string, NumberedNode>();
};

export const getHtmlId = (node: NumberedNode) => {
  switch (node.type) {
    case 'heading':
      return `i${node.numbering}`;
    case 'imageFigure':
      return `fig${node.numbering}`;
    case 'chartFigure':
      return `chart${node.numbering}`;
    case 'tableFigure':
      return `tab${node.numbering}`;
    case 'app':
      return `app${node.numbering}`;
    default:
      return '';
  }
};

export const useUpdateNumberedNodesOnEditor = () => {
  const context = useGuideCmsContext();
  return (editor: Editor) => updateNumberedNodes(editor, context);
};

const updateNumberedNodes = (editor: Editor, context: GuideCmsContext) => {
  const numberedNodes = getNumberedNodes(editor.getJSON());
  if (editor.isDestroyed) return;
  editor.storage['numberedNodes'] = numberedNodes;
  context.forceRefreshEditors();
};

const getNumberedNodes = (root: JSONContent): NumberedNodes => {
  const numberedNodes: NumberedNodes = new Map<string, NumberedNode>();
  let h1Level = -1;
  let h2Level = 0;
  let h3Level = 0;

  const addNumbersToNodesRecursivelyInternal = (node: JSONContent) => {
    if (node.type === 'heading') {
      if (!node.attrs || !node.attrs.id) return;

      if (node.attrs.level === 1) {
        h1Level++;
        h2Level = 0;
        h3Level = 0;
      } else if (node.attrs.level === 2) {
        h2Level++;
        h3Level = 0;
      } else if (node.attrs.level === 3) {
        h3Level++;
      }

      numberedNodes.set(node.attrs.id, {
        type: 'heading',
        id: node.attrs.id,
        numbering: getNumbering(h1Level, h2Level, h3Level),
        h1Level,
        h2Level,
        h3Level,
      });
    } else if (node.type === 'imageFigure' || node.type === 'tableFigure' || node.type === 'chartFigure' || node.type === 'app') {
      if (!node.attrs || !node.attrs.id) return;

      const numbering = getNumbering(h1Level, h2Level, h3Level);

      // Add letters to other nodes at the same level
      let numberOfNodesAtSameLevel = 0;
      for (const [, n] of numberedNodes) {
        if (isSameLogicalType(n.type, node.type) && n.h1Level === h1Level && n.h2Level === h2Level && n.h3Level === h3Level) {
          n.numbering = `${numbering} ${getLetterFromNumber(numberOfNodesAtSameLevel)}`;
          numberOfNodesAtSameLevel++;
        }
      }

      numberedNodes.set(node.attrs.id, {
        type: node.type,
        id: node.attrs.id,
        numbering: numberOfNodesAtSameLevel > 0 ? `${numbering} ${getLetterFromNumber(numberOfNodesAtSameLevel)}` : numbering,
        h1Level,
        h2Level,
        h3Level,
      });
    }
    if (node.type === 'tableFigure') {
      return;
    }
    for (const child of node.content ?? []) {
      addNumbersToNodesRecursivelyInternal(child);
    }
  };

  addNumbersToNodesRecursivelyInternal(root);
  return numberedNodes;
};
