import React, { FC } from 'react';
import { Badge, IconButton, LinearProgress, Link, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Delete, Image, Reorder } from '@mui/icons-material';
import Dropzone, { Accept } from 'react-dropzone';
import { UPLOAD_FIGURE } from '../content.graphql';
import { useMutation } from '@apollo/client';
import { useGuideCmsContext } from '../GuideCmsContext';
import { FigureFileType } from '../../../__generated__/graphql';
import { ImageMetadataDialog, ImageMetadataDialogProps } from './ImageMetadataDialog';
import { useModal } from '../../../dialogs/useModal';
import { useFiguresContext } from './FiguresContext';
import { getFigureFileUrl } from './figureHelper';
import { useConfig } from '../../../env';

const getFigureFileTypeDescription = (fileType: FigureFileType) => {
  switch (fileType) {
    case FigureFileType.Large:
      return 'Stor';
    case FigureFileType.Small:
      return 'Liten';
    case FigureFileType.Original:
      return 'Dwg';
  }
};

export const UploadGuideFigureFiles: FC = () => {
  return (
    <Stack spacing={2}>
      <UploadGuideFigure fileType={FigureFileType.Small} />
      <UploadGuideFigure fileType={FigureFileType.Large} />
      <UploadGuideFigure fileType={FigureFileType.Original} />
    </Stack>
  );
};

const UploadGuideFigure: FC<{
  fileType: FigureFileType;
}> = ({ fileType }) => {
  const { guideId } = useGuideCmsContext();
  const config = useConfig();
  const { editingFigure, addFile, deleteFile } = useFiguresContext();

  const [uploadFigureMutation, { loading: isUploading }] = useMutation(UPLOAD_FIGURE);

  const file = editingFigure?.files?.find((f) => f?.type === fileType);
  const metadataModal = useModal<ImageMetadataDialogProps, undefined>({ data: file });

  const handleOnDrop = async (files: File[]) => {
    const file = files[0];
    const result = await uploadFigureMutation({ variables: { input: { file, figureId: editingFigure?.figureId, fileType, guideId } } });

    const createdFile = result.data?.guideUploadFigureFile?.figureFile;
    if (createdFile) {
      addFile(createdFile);
    }
  };

  const handleOnDelete = (fileId: string) => {
    deleteFile(fileId);
  };

  const handleShowMetadata = () => {
    metadataModal.open(file);
  };

  if (!editingFigure) return null;

  return (
    <div>
      <Typography variant="h6">{getFigureFileTypeDescription(fileType)}</Typography>
      {!file && <FileChooser disabled={isUploading} onDrop={handleOnDrop} />}
      <div>
        {file && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Image color="action" />
            <div>
              <Link title="Åpne fil" href={getFigureFileUrl(config, guideId, editingFigure?.figureId, file) ?? '#'} target="_blank">
                {file.fileName}
              </Link>
            </div>
            <IconButton title={file.metadata?.length ? 'Se metadata' : 'Mangler metadata'} onClick={handleShowMetadata}>
              {file.metadata?.length ? (
                <Reorder />
              ) : (
                <Badge badgeContent="!" color="error">
                  <Reorder />
                </Badge>
              )}
            </IconButton>
            <IconButton onClick={() => handleOnDelete(file.id)}>
              <Delete />
            </IconButton>
          </Stack>
        )}

        {isUploading && <LinearProgress sx={{ mt: 2 }} variant="indeterminate" />}
      </div>
      {metadataModal.isOpen && <ImageMetadataDialog modal={metadataModal} />}
    </div>
  );
};

const StyledDropzone = styled.div`
  padding: 0 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
`;

const FileChooser: FC<{ disabled?: boolean; accept?: Accept; onDrop: (newFiles: File[]) => void }> = ({ disabled, onDrop, accept }) => {
  return (
    <Dropzone disabled={disabled} onDrop={onDrop} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <StyledDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Dra og slipp fil her, eller klikk for å velge</p>
        </StyledDropzone>
      )}
    </Dropzone>
  );
};
