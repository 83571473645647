import { FC, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid2 as Grid,
  Typography,
  List,
  ListItemText,
  ListItemButton,
  Stack,
} from '@mui/material';
import React from 'react';
import { Modal } from '../../../dialogs/useModal';
import { getNodesOfType, getText } from '../tiptap/tiptapHelper';
import { NumberedNodeType, NumberedNodes, getLongTypeDescription, getTypeDescription, useNumberedNodes } from '../tiptap/numbering';
import { Extension, GridOn, Image, Toc } from '@mui/icons-material';
import { useGuideCmsContext } from '../GuideCmsContext';
import { SearchField } from '../../../components/SearchField';
import { JSONContent } from '@tiptap/core';
import Fuse from 'fuse.js';
import { InternalLinkFormat } from '../tiptap/extensions/plugins/InternalLink';

export interface InternalLinksDialogResult {
  id: string;
  type: string;
  format?: InternalLinkFormat;
}

export interface InternalLinksDialogProps {
  selectedText?: string;
}

interface InternalLinkItem {
  id: string;
  type: NumberedNodeType;
  text: string;
  altText1: string;
  altText2: string;
  description?: string;
}

const mapJsonContentToInternalLinkItem = (content: JSONContent, numberedNodes: NumberedNodes): InternalLinkItem => {
  return {
    id: content.attrs!.id,
    type: content.type as NumberedNodeType,
    text: `${getLongTypeDescription(content.type as NumberedNodeType)} ${numberedNodes.get(content.attrs!.id)?.numbering}`,
    altText1: `${getTypeDescription(content.type as NumberedNodeType)} ${numberedNodes.get(content.attrs!.id)?.numbering}`,
    altText2: content.type === 'heading' ? `[${numberedNodes.get(content.attrs!.id)?.numbering}]` : '',
    description: content.type === 'heading' ? getText(content) : undefined,
  };
};

export const InternalLinksDialog: FC<Modal<InternalLinksDialogProps, InternalLinksDialogResult>> = (modal) => {
  const { close } = modal;
  const { editor } = useGuideCmsContext();
  const numberedNodes = useNumberedNodes();
  const handleOnClose = () => {
    close();
  };
  const handleOnInsert = (type: NumberedNodeType, id: string, format?: InternalLinkFormat) => {
    close({ type, id, format });
  };
  const [filter, setFilter] = useState(modal.data.selectedText ?? '');

  const editorContent = useMemo(() => editor?.getJSON(), []);

  const filterInternalLinkItems = (items: InternalLinkItem[]): InternalLinkItem[] => {
    if (!items) return [];
    if (!filter) return items;

    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      keys: ['text', 'altText1', 'altText2'],
    };

    const fuse = new Fuse(items, options);
    const filtered = fuse.search(filter.trim()).map((x) => x.item);
    return filtered;
  };

  const figures = useMemo(
    () =>
      editorContent
        ? getNodesOfType(editorContent, ['imageFigure', 'chartFigure']).map((node) => mapJsonContentToInternalLinkItem(node, numberedNodes))
        : [],
    [],
  );
  const tables = useMemo(
    () => (editorContent ? getNodesOfType(editorContent, 'tableFigure').map((node) => mapJsonContentToInternalLinkItem(node, numberedNodes)) : []),
    [],
  );
  const headings = useMemo(
    () => (editorContent ? getNodesOfType(editorContent, 'heading').map((node) => mapJsonContentToInternalLinkItem(node, numberedNodes)) : []),
    [],
  );
  const apps = useMemo(
    () => (editorContent ? getNodesOfType(editorContent, 'app').map((node) => mapJsonContentToInternalLinkItem(node, numberedNodes)) : []),
    [],
  );

  const filteredFigures = useMemo(() => filterInternalLinkItems(figures), [figures, filter]);
  const filteredTables = useMemo(() => filterInternalLinkItems(tables), [tables, filter]);
  const filteredHeadings = useMemo(() => filterInternalLinkItems(headings), [headings, filter]);
  const filteredApps = useMemo(() => filterInternalLinkItems(apps), [apps, filter]);

  return (
    <Dialog open={true} onClose={handleOnClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle>Legg til intern lenke</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid size={{ xs: 12 }} marginBottom={1}>
            <SearchField filter={setFilter} startSearch={filter} />
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Stack direction="row" gap={1} alignItems="center">
              <Toc />
              <Typography variant="h6" component="h3">
                Overskrifter
              </Typography>
            </Stack>
            <List dense>
              {filteredHeadings.map((heading) => (
                <ListItemButton
                  key={heading.id}
                  onClick={() => {
                    const isCitationFormat = filter.startsWith('[');
                    handleOnInsert('heading', heading.id, isCitationFormat ? InternalLinkFormat.CitationIEEEStyle : InternalLinkFormat.Default);
                  }}>
                  <ListItemText secondary={heading.description}>{heading.text}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Stack direction="row" gap={1} alignItems="center">
              <Image />
              <Typography variant="h6" component="h3">
                Figurer
              </Typography>
            </Stack>
            <List dense>
              {filteredFigures.map((figure) => (
                <ListItemButton key={figure.id} onClick={() => handleOnInsert('imageFigure', figure.id)}>
                  <ListItemText>{figure.text}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Stack direction="row" gap={1} alignItems="center">
              <GridOn />
              <Typography variant="h6" component="h3">
                Tabeller
              </Typography>
            </Stack>
            <List dense>
              {filteredTables.map((table) => (
                <ListItemButton key={table.id} onClick={() => handleOnInsert('tableFigure', table.id)}>
                  <ListItemText>{table.text}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Stack direction="row" gap={1} alignItems="center">
              <Extension />
              <Typography variant="h6" component="h3">
                Applikasjoner
              </Typography>
            </Stack>
            <List dense>
              {filteredApps.map((app) => (
                <ListItemButton key={app.id} onClick={() => handleOnInsert('app', app.id)}>
                  <ListItemText>{app.text}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
      </DialogActions>
    </Dialog>
  );
};
