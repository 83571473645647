import React, { useState } from 'react';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { getFullname, getInitials } from '../user';
import { UnreadTasksBadge } from '../tasks/UnreadTasksBadge';

export function UserAvatar() {
  return (
    <MyTasksDecorator>
      <InitialsAvatar />
    </MyTasksDecorator>
  );
}

function InitialsAvatar() {
  const auth = useAuth();
  const profile = auth.userData?.profile;
  const initials = getInitials(profile);

  return <Avatar>{initials}</Avatar>;
}

function MyTasksDecorator({ children }: { children: any }) {
  const auth = useAuth();
  const fullname = getFullname(auth.userData?.profile);
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UnreadTasksBadge overlap="rectangular" aria-controls="avatar_menu" aria-haspopup="true" onClick={handleMenu} sx={{ cursor: 'pointer' }}>
        {children}
      </UnreadTasksBadge>
      <Menu
        id="avatar_menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        open={open}>
        <MenuItem onClick={() => auth.signOutRedirect()}>Logg ut {fullname}</MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/task?mine=1" style={{ textDecoration: 'none', color: 'inherit' }}>
            Gå til mine oppgaver...
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
}
