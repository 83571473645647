import { FC, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Stack, Typography } from '@mui/material';
import React from 'react';
import { Modal } from '../../../dialogs/useModal';
import { useGuideCmsContext } from '../GuideCmsContext';
import { CREATE_NEW_GUIDE_CONTENT_VERSION } from '../content.graphql';
import { useMutation } from '@apollo/client';

export interface VersionCommentDialogDialogResult {}

export interface VersionCommentDialogProps {}

export const VersionCommentDialog: FC<Modal<VersionCommentDialogProps, VersionCommentDialogDialogResult>> = (modal) => {
  const { editor, guideId } = useGuideCmsContext();
  const [createNewContentVersion, { loading: isCreatingNewContentVersion }] = useMutation(CREATE_NEW_GUIDE_CONTENT_VERSION);
  const [comment, setComment] = useState('');
  const { close } = modal;
  const handleOnClose = () => {
    close();
  };
  const handleOnUpdate = async () => {
    if (!editor) {
      return;
    }
    const success = editor.commands.saveVersion(comment);
    if (!success) {
      return;
    }
    setTimeout(async () => {
      // Allow some time before the new version is created in the cloud
      await createNewContentVersion({ variables: { input: { id: guideId } } });
      close({});
    }, 1000);
  };

  return (
    <Dialog open={true} onClose={handleOnClose} fullWidth={true}>
      <DialogTitle>Lagre ny versjon av innholdet</DialogTitle>
      <DialogContent>
        <Stack>
          <Typography variant="body1" gutterBottom>
            Legg til en beskrivelse av endringene dine
          </Typography>
          <TextField multiline minRows={4} fullWidth value={comment} onChange={(e) => setComment(e.target.value)} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
        <Button disabled={isCreatingNewContentVersion} variant="contained" onClick={handleOnUpdate}>
          Lagre
        </Button>
      </DialogActions>
    </Dialog>
  );
};
