import { useCallback } from 'react';
import { useAuthenticatedApi } from '../../api';

export interface AuthenticationResult {
  token: string;
}

export const useCmsCollaborationApi = () => {
  const api = useAuthenticatedApi('cms-collaboration');

  const authenticate = useCallback(async () => {
    const response = await api.post<AuthenticationResult | string>('authenticate');
    if (response.status === 200) return response.data as AuthenticationResult;
    throw new Error(response.data as string);
  }, [api]);

  return {
    authenticate,
  };
};
