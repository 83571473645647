import React from 'react';
import { useMutation } from '@apollo/client';
import { Alert, AlertTitle, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { IMPORT_LAST_PUBLISHED_VERSION } from './content.graphql';
import LoadingSpinner from '../../LoadingSpinner';
import { ArrowBack, CloudDownload, Edit, MoreTime, MoreVert, Schedule, Visibility } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { GuideEditor } from './tiptap/GuideEditor';
import { useGuideEditor } from './tiptap/useGuideEditor';
import { StyledPaper } from '../../theme';
import { getTipTapJsonContent } from './tiptap/tiptapHelper';
import { Link, useNavigate } from 'react-router-dom';
import { TiptapCollabConnectedUsers } from './tiptapCollab/TiptapCollabConnectedUsers';
import { TiptapCollabConnectionStatus } from './tiptapCollab/TiptapCollabConnectionStatus';
import { useModal } from '../../dialogs/useModal';
import { useGuideCmsContext } from './GuideCmsContext';
import { useUpdateNumberedNodesOnEditor } from './tiptap/numbering';
import { VersionCommentDialog, VersionCommentDialogProps, VersionCommentDialogDialogResult } from './dialogs/CreateNewVersionDialog';
import { useConfig } from '../../env';
import { useCommentsContext } from './sidebar/comments/CommentsContext';
import { HistoryDialog, HistoryDialogProps, HistoryDialogResult } from './history/HistoryDialog';
import { CollabHistoryStorage } from '@tiptap-pro/extension-collaboration-history';

export const GuideCms: FC<{ isEditing: boolean }> = ({ isEditing }) => {
  const { guideId, editor, provider } = useGuideCmsContext();
  const historyModal = useModal<HistoryDialogProps, HistoryDialogResult>({ data: {} });

  const handleHistoricChangesClick = () => {
    historyModal.open({ guideId, editor, provider });
  };

  return (
    <>
      {historyModal.isOpen && <HistoryDialog {...historyModal} />}
      {isEditing ? <GuideCmsEditable onHistoricChangesClick={handleHistoricChangesClick} /> : <GuideCmsReadOnly />}
    </>
  );
};

const GuideCmsEditable: FC<{ onHistoricChangesClick: () => void }> = ({ onHistoricChangesClick }) => {
  const { guideId, isLoading, isValidSchema, setEditor, setProvider, forceRefreshEditors } = useGuideCmsContext();
  const { closeComments } = useCommentsContext();

  const { editor, tiptapCollab } = useGuideEditor({
    onEditorCreated: (editor, provider) => {
      setEditor(editor);
      if (provider) {
        setProvider(provider);
      }
    },
    isEditable: true,
  });
  const collabHistory = editor?.storage.collabHistory as CollabHistoryStorage;

  const updateNumberedNodesOnEditor = useUpdateNumberedNodesOnEditor();

  const [
    importLastPublishedVersion,
    { loading: isImportingLastPublishedVersion, error: importLastPublishedVersionError, reset: resetImportLastPublishedVersionError },
  ] = useMutation(IMPORT_LAST_PUBLISHED_VERSION);

  const onCreateNewVersionClick = async () => {
    await versionCommentDialog.open();
  };

  const onImportLastPublishedVersionClick = async () => {
    try {
      const result = await importLastPublishedVersion({ variables: { input: { id: guideId } } });
      const lastPublishedVersion = result?.data?.guideImportLastPublishedVersion?.importLastPublishedVersionPayload?.cmsContent;
      if (!editor) return;
      editor.commands.setContent(getTipTapJsonContent(lastPublishedVersion ?? ''));
      updateNumberedNodesOnEditor(editor);
      forceRefreshEditors();
      closeComments();
    } catch (e) {
      console.error('Failed load content from Word\n\n', e);
    }
  };

  const versionCommentDialog = useModal<VersionCommentDialogProps, VersionCommentDialogDialogResult>({ data: {} });
  const openChangeHistoryModal = async () => {
    onHistoricChangesClick();
  };
  return (
    <StyledPaper>
      {versionCommentDialog.isOpen && <VersionCommentDialog {...versionCommentDialog} />}
      <Stack gap={2}>
        {!isLoading && (
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            <Stack direction="row" gap={1} alignItems="center">
              <Title />
            </Stack>
          </Stack>
        )}
        {!isImportingLastPublishedVersion && importLastPublishedVersionError && (
          <Alert severity="error" onClose={() => resetImportLastPublishedVersionError()}>
            Klarte ikke laste innhold fra sist publiserte anvisning. Vennligst prøv igjen.
          </Alert>
        )}
        {!isValidSchema && (
          <Alert
            severity="error"
            action={
              <Button variant="contained" color="error" onClick={() => window.location.reload()}>
                Last siden på nytt
              </Button>
            }>
            <AlertTitle>Gammel versjon av CMS</AlertTitle>
            Du kjører en utdatert versjon av CMS dataformatet. Vennligst last siden på nytt for å fortsette på nyeste format.
          </Alert>
        )}
        {(isLoading || isImportingLastPublishedVersion) && <LoadingSpinner />}
        {!isLoading && !isImportingLastPublishedVersion && editor && (
          <GuideEditor
            guideId={guideId}
            editor={editor}
            menuComponent={
              <Stack direction="row" flexWrap="wrap">
                <Stack direction="row" gap={1} marginLeft="auto" alignItems="center">
                  <Stack direction="row" gap={2}>
                    <TiptapCollabConnectionStatus connectionStatus={tiptapCollab.connectionStatus} />
                    <TiptapCollabConnectedUsers users={tiptapCollab.connectedUsers} />
                  </Stack>
                  <PreviewButton />

                  <Button
                    variant="contained"
                    disabled={!tiptapCollab.connectionStatus.isConnected}
                    onClick={onCreateNewVersionClick}
                    startIcon={<MoreTime />}>
                    Ny versjon...
                  </Button>

                  <ActionsMenu>
                    <PreviewMenuItem />

                    <MenuItem
                      disabled={collabHistory?.versions?.length === 0}
                      onClick={() => {
                        openChangeHistoryModal();
                      }}>
                      <ListItemIcon>
                        <Schedule fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Tidligere versjoner av innholdet...</ListItemText>
                    </MenuItem>

                    <MenuItem
                      disabled={isImportingLastPublishedVersion}
                      onClick={() => {
                        onImportLastPublishedVersionClick();
                      }}>
                      <ListItemIcon>
                        <CloudDownload fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Last inn sist publiserte anvisning</ListItemText>
                    </MenuItem>
                  </ActionsMenu>
                </Stack>
              </Stack>
            }
          />
        )}
      </Stack>
    </StyledPaper>
  );
};

const GuideCmsReadOnly: FC = () => {
  const navigate = useNavigate();
  const { guideId, isLoading, setEditor } = useGuideCmsContext();
  const { editor: readOnlyEditor } = useGuideEditor({
    onEditorCreated: (editor) => {
      setEditor(editor);
    },
    isEditable: false,
  });

  const onEditClick = async () => {
    navigate('./edit', { relative: 'path', replace: true });
  };

  return (
    <StyledPaper>
      <Stack gap={2}>
        {!isLoading && (
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            <Stack direction="row" gap={1} alignItems="center">
              <Title />
            </Stack>
            <Stack direction="row" gap={2} marginLeft="auto" alignItems="center">
              <Button variant="contained" onClick={onEditClick} startIcon={<Edit />}>
                Endre
              </Button>

              <ActionsMenu>
                <PreviewMenuItem />
              </ActionsMenu>
            </Stack>
          </Stack>
        )}
        {isLoading && <LoadingSpinner />}
        {!isLoading && readOnlyEditor && <GuideEditor guideId={guideId} editor={readOnlyEditor} />}
      </Stack>
    </StyledPaper>
  );
};

const ActionsMenu = ({ children }: { children: React.ReactNode }) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
  };
  return (
    <div>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menu open={isMenuOpen} onClick={closeMenu} onClose={closeMenu} anchorEl={menuAnchorEl} disableScrollLock>
        {children}
      </Menu>
    </div>
  );
};

const PreviewButton: FC = () => {
  const config = useConfig();
  const { guide } = useGuideCmsContext();
  const previewUrl = `${config?.bksUrl}/dokument/${guide?.docId}?version=preview`;

  return (
    <Tooltip title="Åpne forhåndsvisning">
      <IconButton href={previewUrl} component="a" target="_blank">
        <Visibility />
      </IconButton>
    </Tooltip>
  );
};

const PreviewMenuItem: FC = () => {
  const config = useConfig();
  const { guide } = useGuideCmsContext();
  const previewUrl = `${config?.bksUrl}/dokument/${guide?.docId}?version=preview`;

  return (
    <MenuItem href={previewUrl} component="a" target="_blank">
      <ListItemIcon>
        <Visibility />
      </ListItemIcon>
      <ListItemText>Forhåndsvisning</ListItemText>
    </MenuItem>
  );
};

const Title: FC = ({}) => {
  const { guideId, guide, editor } = useGuideCmsContext();
  if (!editor) {
    return null;
  }
  const version = (editor.storage.collabHistory as CollabHistoryStorage)?.currentVersion;
  const showVersion = (editor.storage.collabHistory as CollabHistoryStorage)?.versions?.length > 0;
  return (
    <Typography variant="h5">
      <IconButton component={Link} to={`/guide/${guideId}`} size="large">
        <ArrowBack />
      </IconButton>{' '}
      {guide?.docName} {guide?.docTitle} {showVersion && <>(v. {version})</>}
    </Typography>
  );
};
