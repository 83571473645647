import React, { FC } from 'react';
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { InternalLinkExtensionRenderOptions, InternalLinkFormat } from '../plugins/InternalLink';
import { NumberedNode, getTypeDescription, useNumberedNodes } from '../../numbering';
import { Tooltip } from '@mui/material';
import { Link, Image, InsertChart, GridOn, Extension } from '@mui/icons-material';

const renderText = (numberedNodes?: Map<string, NumberedNode>) => (node: ProseMirrorNode) => {
  const numberedNode = numberedNodes?.get(node.attrs.id);
  return `${getTypeDescription(node.attrs.type, node.attrs.format)} ${numberedNode?.numbering}`;
};

const getTooltipText = ({ node, editor }: NodeViewProps) => {
  if (!editor.isEditable) return null;
  if (node.attrs.type === 'heading') {
    const format = node.attrs.format;
    if (format === InternalLinkFormat.CitationIEEEStyle) {
      return 'Klikk for å endre lenke til punkt';
    }
    return 'Klikk for å endre lenke til referanse';
  }
  return null;
};

const InternalLinkView: FC<NodeViewProps> = (props) => {
  const numberedNodes = useNumberedNodes(props.editor);
  return (
    <NodeViewWrapper className="react-component" as={'span'}>
      <Tooltip title={getTooltipText(props)}>
        <span
          className={`link link-internal-${props.node.attrs.type}`}
          onClick={() => {
            if (!props.editor.isEditable) return;
            const format = props.node.attrs.format;
            const newFormat = format === InternalLinkFormat.CitationIEEEStyle ? InternalLinkFormat.Default : InternalLinkFormat.CitationIEEEStyle;
            props.updateAttributes({
              ...props.node.attrs,
              format: newFormat,
            });
          }}>
          <span className="link-icon">
            {props.node.attrs.type === 'heading' && <Link />}
            {props.node.attrs.type === 'imageFigure' && <Image />}
            {props.node.attrs.type === 'chartFigure' && <InsertChart />}
            {props.node.attrs.type === 'tableFigure' && <GridOn />}
            {props.node.attrs.type === 'app' && <Extension />}
          </span>{' '}
          {renderText(numberedNodes)(props.node)}
        </span>
      </Tooltip>
    </NodeViewWrapper>
  );
};

declare global {
  interface Window {
    guideCmsNumberedNodes: Map<string, NumberedNode>;
  }
}

export const getInternalLinkExtensionRenderOptions = (): InternalLinkExtensionRenderOptions => {
  return {
    viewComponent: InternalLinkView,
    renderText: renderText(),
  };
};
