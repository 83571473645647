import { getFullname, getInitials } from '../../../user';
import { IdTokenClaims } from 'oidc-client-ts';

export interface CollaborationUser {
  id: string;
  name: string;
  email: string;
  initials: string;
  color: string;
}

export const createUserFromProfile = (profile?: IdTokenClaims): CollaborationUser => {
  const fullname = getFullname(profile);
  return {
    id: profile?.sub!,
    name: fullname,
    email: profile?.email?.toLowerCase() ?? '',
    initials: getInitials(profile),
    color: getUserColor(profile?.name ?? ''),
  };
};
const colors = [
  '#958DF1',
  '#F98181',
  '#FBBC88',
  '#FAF594',
  '#70CFF8',
  '#94FADB',
  '#B9F18D',
  '#568259',
  '#A4A8D1',
  '#E6AF2E',
  '#406E8E',
  '#D5C67A',
  '#B4436C',
  '#5FAD56',
  '#4D9078',
];

// Get a color for a user based on their name. Will return the same color for the same name.
function getUserColor(name: string): string {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);

  const index = hash % colors.length;
  return colors[index];
}
