import React from 'react';
import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { CloudDoneOutlined, CloudOff } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { ConnectionStatus } from './useTiptapCollab';

export const TiptapCollabConnectionStatus: FC<{ connectionStatus: ConnectionStatus }> = ({ connectionStatus }) => {
  const { isConnected, isConnecting } = connectionStatus;

  return (
    <Stack direction="row" spacing={1}>
      {!isConnected && (
        <Tooltip title={<>{isConnecting && <>Kobler til server...</>}</>}>
          <CloudOff color="error" />
        </Tooltip>
      )}
      {!isConnecting && isConnected && (
        <Tooltip title={<>Automatisk lagring aktivert.</>}>
          <CloudDoneOutlined color="success" />
        </Tooltip>
      )}
    </Stack>
  );
};
