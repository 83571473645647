import React, { FC, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { Modal, useModal } from '../dialogs/useModal';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { EditableTextField } from '../components/EditableTextField';
import { ConfirmDialog, ConfirmDialogProps } from '../dialogs/ConfirmDialog';
import {
  ADD_HEARING_PARTICIPANTS,
  END_HEARING,
  REMOVE_HEARING_PARTICIPANT,
  START_HEARING,
  UPDATE_HEARING_EMAIL_MESSAGE,
  UPDATE_HEARING_IS_EXTERNAL,
  UPDATE_HEARING_SCHEDULED_END_AT,
} from './hearing.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { formatDate } from '../Formatters';
import { GET_GUIDE } from '../guides/guide.graphql';
import LoadingSpinner from '../LoadingSpinner';

export interface HearingDialogProps {
  guideId: string;
}

export interface HearingDialogResult {}

export const HearingDialog: FC<Modal<HearingDialogProps, void>> = (modal) => {
  const guideResponse = useQuery(GET_GUIDE, { variables: { id: modal.data.guideId } });
  const hearing = guideResponse?.data?.guide?.hearing;
  const loading = guideResponse.loading;

  const [startHearing, { loading: isStarting }] = useMutation(START_HEARING);
  const [endHearing, { loading: isEnding }] = useMutation(END_HEARING);
  const [updateIsExternal, { loading: isUpdatingIsExternal }] = useMutation(UPDATE_HEARING_IS_EXTERNAL);
  const [updateScheduledEndAt, { loading: isUpdatingScheduledEndAt }] = useMutation(UPDATE_HEARING_SCHEDULED_END_AT);
  const [updateEmailMessage] = useMutation(UPDATE_HEARING_EMAIL_MESSAGE);
  const [addParticipants] = useMutation(ADD_HEARING_PARTICIPANTS);
  const [removeParticipant] = useMutation(REMOVE_HEARING_PARTICIPANT);

  const confirmModal = useModal<ConfirmDialogProps, boolean>({
    data: {
      message: '',
      title: '',
    },
  });

  const handleOnClose = () => {
    modal.close();
  };

  if (loading) {
    return (
      <Dialog open={true} maxWidth="md" fullWidth={true} onClose={handleOnClose}>
        <DialogTitle>Høring</DialogTitle>
        <DialogContent>
          <LoadingSpinner />
        </DialogContent>
      </Dialog>
    );
  }
  if (!hearing) {
    return (
      <Dialog open={true} maxWidth="md" fullWidth={true} onClose={handleOnClose}>
        <DialogTitle>Høring</DialogTitle>
        <DialogContent>
          <Alert severity="info">Høringen er avsluttet</Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => modal.close()}>
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const participantsEmails = (hearing.participants?.filter((p) => p?.email).map((p) => p?.email) as string[]) ?? [];

  const onHearingStartClick = async () => {
    const shouldStart = await confirmModal.open({
      message: (
        <>
          Er du sikker på at du vil starte høringen?
          <br />
          Deltakerne vil få en epost om at høringen er startet.
        </>
      ),
      title: 'Start høring',
      submitText: 'Start høring',
      cancelText: 'Avbryt',
    });
    if (shouldStart) {
      await startHearing({ variables: { input: { id: hearing.id } } });
    }
  };
  const onHearingStopClick = async () => {
    const shouldStop = await confirmModal.open({
      message: (
        <>
          Er du sikker på at du vil avslutte høringen?
          <br />
          Deltakerne vil ikke lenger ha mulighet til å kommentere på anvisningen.
          <br />
          Det er ikke mulig å starte høringen igjen.
        </>
      ),
      title: 'Avslutt høring',
      submitText: 'Avslutt høring',
      cancelText: 'Avbryt',
    });
    if (shouldStop) {
      await endHearing({ variables: { input: { id: hearing.id } } });
    }
  };
  return (
    <>
      {confirmModal.isOpen && <ConfirmDialog modal={confirmModal} />}
      <Dialog open={true} maxWidth="md" fullWidth={true} onClose={handleOnClose}>
        <DialogTitle>Høring</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box sx={{ paddingTop: '10px' }}>
              <ParticipantsEmailInput
                participantsEmails={participantsEmails}
                onAdd={async (email) => {
                  await addParticipants({ variables: { input: { id: hearing.id, emails: [email] } } });
                }}
                onBatchAdd={async (emails) => {
                  await addParticipants({ variables: { input: { id: hearing.id, emails } } });
                }}
                onRemove={async (email) => {
                  const participant = hearing.participants?.find((p) => p?.email === email);
                  if (participant?.id) {
                    await removeParticipant({ variables: { input: { id: hearing.id, participantId: participant?.id } } });
                  }
                }}
              />
            </Box>
            <div>
              <FormControlLabel
                disabled={isUpdatingIsExternal}
                control={
                  <Switch
                    checked={hearing.isExternal}
                    onChange={async (_, checked) => {
                      await updateIsExternal({ variables: { input: { id: hearing.id, isExternal: checked } } });
                    }}
                  />
                }
                label="Ekstern høring"
              />
            </div>
            <div>
              <DatePicker
                disabled={isUpdatingScheduledEndAt}
                format="DD.MM.YYYY"
                label="Avsluttes automatisk"
                minDate={moment().add(1, 'days')}
                value={hearing.scheduledEndAt ? moment(hearing.scheduledEndAt) : null}
                disablePast={true}
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
                onChange={async (date, context) => {
                  if (!context.validationError) {
                    await updateScheduledEndAt({ variables: { input: { id: hearing.id, endsAt: date?.toDate() } } });
                  }
                }}
              />
            </div>
            <div>
              <EditableTextField
                value={hearing.emailMessage ?? ''}
                label="Beskjed til deltakerne (sendes på e-post)"
                onUpdate={async (value) => {
                  await updateEmailMessage({ variables: { input: { id: hearing.id, emailMessage: value } } });
                }}
                TextFieldProps={{
                  multiline: true,
                  variant: 'standard',
                  placeholder: 'Beskjed til deltakerne (sendes på e-post)',
                }}
              />
            </div>
            {hearing.startedAt && (
              <Alert severity="info">
                Høringen ble startet {formatDate(hearing.startedAt)}.
                <p>
                  <Button disabled={isEnding} variant="contained" onClick={onHearingStopClick}>
                    Avslutt høring
                  </Button>
                </p>
              </Alert>
            )}
            {!hearing.startedAt && (
              <Alert severity="info">
                Høringen er ikke startet ennå
                <p>
                  <Button disabled={isStarting} variant="contained" onClick={onHearingStartClick}>
                    Start høring
                  </Button>
                </p>
              </Alert>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => modal.close()}>
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ParticipantsEmailInputProps {
  participantsEmails: string[];
  onBatchAdd: (participantEmails: string[]) => Promise<void>;
  onAdd: (participantEmail: string) => Promise<void>;
  onRemove: (participantEmail: string) => Promise<void>;
}

export const ParticipantsEmailInput: FC<ParticipantsEmailInputProps> = ({ participantsEmails, onAdd, onBatchAdd, onRemove }) => {
  const confirmModal = useModal<ConfirmDialogProps, boolean>({
    data: {
      message: '',
      title: '',
    },
  });
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [error, setError] = useState(false);
  const [pasteError, setPasteError] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const isValidEmail = (email: string) => emailRegex.test(email);

  return (
    <>
      {confirmModal.isOpen && <ConfirmDialog modal={confirmModal} />}
      <Autocomplete
        multiple
        sx={{ width: '100%' }}
        options={[]}
        onPaste={async (event) => {
          event.preventDefault();
          setPasteError(false);
          const clipboardData = event.clipboardData.getData('Text');
          const emails = clipboardData.split('\r\n');
          if (emails.some((email) => !isValidEmail(email))) {
            setPasteError(true);
            return;
          }
          await onBatchAdd(emails.filter((email) => !participantsEmails.includes(email)));
        }}
        onBlur={() => {
          setInputValue('');
        }}
        defaultValue={[]}
        freeSolo
        disableClearable
        inputValue={inputValue}
        onInputChange={(_, value) => {
          setError(false);
          setPasteError(false);

          setInputValue(value);
        }}
        value={participantsEmails}
        onChange={async (_event, _values: string[], reason, details) => {
          if (!details?.option) return;
          if (reason === 'removeOption') {
            const shouldDeleteParticipant = await confirmModal.open({
              message: `Er du sikker på at du vil fjerne ${details?.option} fra høringen?`,
              title: 'Fjern deltaker',
              submitText: 'Fjern',
              cancelText: 'Avbryt',
            });
            if (shouldDeleteParticipant) {
              await onRemove(details?.option);
            }
          }
          if (reason === 'createOption') {
            setInputValue(details?.option ?? '');
            if (!isValidEmail(details?.option)) {
              setInputValue(details?.option);
              setError(true);
            } else {
              await onAdd(details?.option);
              setInputValue('');
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Deltakere"
            placeholder="E-postadresse"
            variant="standard"
            type="email"
            error={error || pasteError}
            helperText={error ? 'Vennligst skriv inn en gyldig e-postadresse' : pasteError ? 'Vennligst lim inn gyldige e-postadresser' : undefined}
          />
        )}
      />
    </>
  );
};
