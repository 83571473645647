import React, { FC } from 'react';
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { StandardLinkExtensionRenderOptions } from '../plugins/StandardLink';
import { Link, LocalLibrary } from '@mui/icons-material';

const renderText = (node: ProseMirrorNode) => {
  return node.attrs.standardName;
};

export const StandardLinkView: FC<NodeViewProps> = (props) => {
  return (
    <NodeViewWrapper className="react-component" as={'span'}>
      <span className="link link-standard" title="Lenke til standard">
        <span className="link-icon">
          <LocalLibrary />
        </span>{' '}
        {props.node.attrs.standardName}
      </span>
    </NodeViewWrapper>
  );
};

export const getStandardLinkExtensionRenderOptions = (): StandardLinkExtensionRenderOptions => {
  return {
    viewComponent: StandardLinkView,
    renderText: (node) => renderText(node),
  };
};
