import React, { FC, useEffect, useState } from 'react';
import { FigureFile, getImageFigures, ImageFigure, mapToAttributesFromImageFigure } from './figureHelper';
import { v4 as uuid } from 'uuid';
import { useGuideCmsContext } from '../GuideCmsContext';
import { GuideFigureDialogProps, GuideFigureDialogResult } from './GuideFigureDialog';
import { Modal } from '../../../dialogs/useModal';
import { getNodesOfType, updateAllMatchingNodeAttributes } from '../tiptap/tiptapHelper';
import { ImageFigureAttributes } from '../tiptap/extensions/plugins/ImageFigure';

interface FiguresContext {
  createFigure: () => ImageFigure;
  editFigure: (figureId: string) => void;
  insertFigure: (figureId: string) => void;
  addFile: (file: FigureFile) => void;
  deleteFile: (fileId: string) => void;
  closeEdit: () => void;
  closeModal: () => void;
  isEditing: boolean;
  isEditingInitially: boolean;
  editingFigure?: ImageFigure;
  figures: ImageFigure[];
}

const FiguresContextInstance = React.createContext<FiguresContext | undefined>(undefined);

export const useFiguresContext = (): FiguresContext => {
  const context = React.useContext(FiguresContextInstance);
  if (!context) {
    throw new Error('useFiguresContext must be used within a FiguresContextProvider');
  }
  return context;
};

export const FiguresContextProvider: FC<{ modal: Modal<GuideFigureDialogProps, GuideFigureDialogResult>; children: React.ReactNode }> = ({
  modal,
  children,
}) => {
  const { editor } = useGuideCmsContext();
  const [isEditingInitially] = useState(!!modal.data.editFigureId);
  const [figures, setFigures] = React.useState<ImageFigure[]>([]);
  const [editingFigure, setEditingFigure] = useState<ImageFigure>();
  useEffect(() => {
    if (!editor) return;
    const figures = getImageFigures(editor);
    setFigures(figures);
    setEditingFigure(modal.data.editFigureId ? figures.find((f) => f.figureId === modal.data.editFigureId) : undefined);
  }, [editor, modal.data.editFigureId]);

  const createFigure = (): ImageFigure => {
    const newFigure: ImageFigure = {
      figureId: uuid(),
      files: [],
    };
    setFigures([...figures, newFigure]);
    setEditingFigure(newFigure);
    return newFigure;
  };

  const editFigure = (figureId: string) => {
    const figure = figures.find((f) => f.figureId === figureId);
    if (!figure) return;

    setEditingFigure(figure);
  };

  const addFile = (file: FigureFile) => {
    if (!editingFigure) return;
    const newFiles = [...editingFigure.files, file];
    const newImageFigure = { ...editingFigure, files: newFiles };
    setEditingFigure(newImageFigure);
    setFigures(figures.map((f) => (f.figureId === newImageFigure.figureId ? newImageFigure : f)));
    updateImageFigureInEditor(newImageFigure);
  };

  const deleteFile = (fileId: string) => {
    if (!editingFigure) return;
    const newFiles = editingFigure.files.filter((f) => f.id !== fileId);
    const newImageFigure = { ...editingFigure, files: newFiles };
    setEditingFigure(newImageFigure);
    setFigures(figures.map((f) => (f.figureId === newImageFigure.figureId ? newImageFigure : f)));
    updateImageFigureInEditor(newImageFigure);
  };

  const updateImageFigureInEditor = (imageFigure: ImageFigure) => {
    if (!editor) return;
    const nodes = getNodesOfType(editor.getJSON(), 'imageFigure');
    const existingAttributes = nodes.map((n) => n.attrs as ImageFigureAttributes).find((attr) => attr.figureId === imageFigure.figureId);
    if (!existingAttributes) return;
    const newAttributes = mapToAttributesFromImageFigure(existingAttributes.id ?? '', imageFigure);
    console.log('newAttributes', newAttributes);
    updateAllMatchingNodeAttributes(
      editor,
      'imageFigure',
      (attr) => (attr as ImageFigureAttributes).figureId === imageFigure.figureId,
      newAttributes,
    );
  };

  const insertFigure = (figureId: string) => {
    const figure = figures.find((f) => f.figureId === figureId);
    if (!figure) return;

    const attributes = mapToAttributesFromImageFigure(uuid(), figure);
    editor?.chain().focus().insertImageFigure(attributes).run();
    modal.close();
  };

  return (
    <FiguresContextInstance.Provider
      value={{
        isEditingInitially,
        isEditing: !!editingFigure,
        createFigure,
        editFigure,
        insertFigure,
        addFile,
        deleteFile,
        closeEdit: () => setEditingFigure(undefined),
        closeModal: () => modal.close(),
        editingFigure,
        figures,
      }}>
      {children}
    </FiguresContextInstance.Provider>
  );
};
