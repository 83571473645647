import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { Image } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { GuideFigureDialog, GuideFigureDialogProps, GuideFigureDialogResult } from '../../../figures/GuideFigureDialog';

export const FigureToolbarButton: FC = () => {
  const editor = useRichTextEditorContext();
  const modal = useModal<GuideFigureDialogProps, GuideFigureDialogResult>({ data: {} });
  const handleOnClick = async () => {
    await modal.open();
  };
  if (!editor) return null;
  return (
    <>
      {modal.isOpen && <GuideFigureDialog {...modal} />}
      <MenuButton disabled={!editor?.isEditable} IconComponent={Image} tooltipLabel="Figurer" onClick={handleOnClick}></MenuButton>
    </>
  );
};
