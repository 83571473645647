import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Stack,
  Typography,
  Alert,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Modal } from '../../../dialogs/useModal';
import styled from '@emotion/styled';
import { theme } from '../../../theme';
import { CollabHistoryStorage, watchPreviewContent } from '@tiptap-pro/extension-collaboration-history';
import { usePreviewGuideEditor } from '../tiptap/useGuideEditor';
import { GuideEditor } from '../tiptap/GuideEditor';
import { useGuideCmsContext } from '../GuideCmsContext';
import { formatDate } from '../../../Formatters';
import { SnapshotCompareStorage } from '@tiptap-pro/extension-snapshot-compare';
import { CREATE_NEW_GUIDE_CONTENT_VERSION } from '../content.graphql';
import { useMutation } from '@apollo/client';
import { useUpdateNumberedNodesOnEditor } from '../tiptap/numbering';
import LoadingSpinner from '../../../LoadingSpinner';
import { Restore } from '@mui/icons-material';

const VersionItem = styled(Paper)<{ selected?: boolean }>`
  padding: ${theme.spacing(1)};
  cursor: pointer;
  ${({ selected }) => (selected ? `border-left: 4px solid ${theme.palette.action.active};` : '')}
  background-color: ${({ selected }) => (selected ? theme.palette.action.selected : 'transparent')};
  &:hover {
    background-color: ${theme.palette.action.hover};
  }
`;

const StyledLayout = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: auto 400px;
`;
const StyledSidebarItems = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledMainContainer = styled.div`
  display: grid;
  padding: 16px;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`;

const StyledGuideEditorContainer = styled.div`
  overflow-y: auto;
  max-height: 100%;
  padding-bottom: 32px;
`;

const StyledSidebarContainer = styled.div`
  border-left: 1px solid ${theme.palette.divider};
  padding: 16px;
  display: grid;
  grid-gap: 16px;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  grid-template-rows: auto 1fr auto;
`;

export interface HistoryDialogResult {
  version: number;
  action: 'navigate' | 'restore';
}

export interface HistoryDialogProps {}

export const HistoryDialog: FC<Modal<HistoryDialogProps, HistoryDialogResult>> = (modal) => {
  const { editor: editorInContext, provider, guideId } = useGuideCmsContext();
  const [createNewContentVersion, { loading: isCreatingNewContentVersion }] = useMutation(CREATE_NEW_GUIDE_CONTENT_VERSION);

  const {
    close,
    data: {},
  } = modal;

  const handleOnClose = () => {
    close();
  };
  const updateNumberedNodesOnEditor = useUpdateNumberedNodesOnEditor();
  const { editor } = usePreviewGuideEditor();
  const collabHistory = editorInContext?.storage.collabHistory as CollabHistoryStorage | undefined;
  const snapshotCompare = editor?.storage.snapshotCompare as SnapshotCompareStorage;

  const [selectedVersion, setSelectedVersion] = useState<number>(collabHistory?.currentVersion ?? -1);
  const [isDiffing, setIsDiffing] = useState<boolean>(false);
  const [diffErrorMessage, setDiffErrorMessage] = useState<string>('');
  const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);
  const shouldShowDiff = isDiffing && selectedVersion > 0;

  useEffect(() => {
    if (modal.isOpen && provider) {
      const unbindContentWatcher = watchPreviewContent(provider, (content) => {
        if (editor && !shouldShowDiff) {
          console.log('Setting content in editor');
          editor.commands.setContent(content, false);
          updateNumberedNodesOnEditor(editor);
          setIsLoadingContent(false);
        }
      });

      return () => {
        unbindContentWatcher();
      };
    }
  }, [modal.isOpen, provider, editor, shouldShowDiff]);

  // Prevent scrolling when modal is open
  useScrollPrevention(modal);

  useEffect(() => {
    if (!editor) {
      return;
    }
    editor.commands.clearContent();
    setIsLoadingContent(true);
    setDiffErrorMessage('');
    if (snapshotCompare.isPreviewing) {
      if (!shouldShowDiff) {
        console.log('Hiding diff view', selectedVersion);
        editor.chain().hideDiff().run();
      }
    }
    if (shouldShowDiff) {
      const fromVersion = selectedVersion - 1;
      const toVersion = selectedVersion;
      console.log(`Diffing ${toVersion} with ${fromVersion}`);
      editor.commands.hideDiff();
      editor.commands.compareVersions({
        fromVersion,
        toVersion,
        onCompare: (context) => {
          if (context.error) {
            setIsLoadingContent(false);
            setDiffErrorMessage(context.error.message);
            console.log(`Failed to diff ${toVersion} with ${fromVersion}`, context.error);
            return;
          }
          if ('editor' in context) {
            context.editor.commands.showDiff(context.tr);
            updateNumberedNodesOnEditor(editor);
            setIsLoadingContent(false);
          }
        },
      });
    } else {
      console.log('Show preview');

      provider?.sendStateless(
        JSON.stringify({
          action: 'version.preview',
          version: selectedVersion,
        }),
      );
    }
  }, [selectedVersion, isDiffing]);

  if (!provider || !editor || !editorInContext) {
    return null;
  }

  const reversedVersions = useMemo(() => collabHistory?.versions?.slice().reverse() ?? [], [collabHistory?.versions]);
  const handleRevertToVersion = async (version: number | null) => {
    if (version === null) {
      return;
    }
    const success = editorInContext.commands.revertToVersion(
      version,
      `Gjenopprettet fra versjon ${version}`,
      'Ulagrede endringer før gjenoppretting',
    );
    if (!success) {
      return;
    }
    setTimeout(async () => {
      // Allow some time before the new version is created in the cloud
      await createNewContentVersion({ variables: { input: { id: guideId } } });
    }, 1000);
  };

  useEffect(() => {
    setSelectedVersion(collabHistory?.currentVersion ?? -1);
  }, [collabHistory?.currentVersion]);

  const handleVersionChange = (newVersion: number) => {
    setSelectedVersion(newVersion);
  };

  return (
    <Dialog open={true} onClose={handleOnClose} maxWidth="xl" fullWidth={true} scroll="paper">
      <DialogTitle>Tidligere versjoner av innholdet</DialogTitle>
      <DialogContent sx={{ height: '90vh', overflow: 'hidden', padding: 0 }} dividers>
        <StyledLayout>
          <StyledMainContainer>
            <StyledGuideEditorContainer>
              {snapshotCompare?.isPreviewing &&
                !isLoadingContent &&
                (diffErrorMessage ? (
                  <Alert severity="error">
                    Klarte ikke sammenligne versjon {selectedVersion} og versjon {selectedVersion - 1}. <br />
                    {diffErrorMessage}
                  </Alert>
                ) : (
                  <Alert severity="info">
                    Sammenligner versjon {selectedVersion} og versjon {selectedVersion - 1}
                  </Alert>
                ))}

              {isLoadingContent && <LoadingSpinner />}
              <GuideEditor guideId={guideId} editor={editor} />
            </StyledGuideEditorContainer>
          </StyledMainContainer>

          <StyledSidebarContainer>
            <FormGroup sx={{ display: 'inline-block' }}>
              <FormControlLabel
                control={<Switch checked={isDiffing} onChange={() => setIsDiffing(!isDiffing)} />}
                label="Sammenlign med forrige versjon"
              />
            </FormGroup>

            <StyledSidebarItems>
              {reversedVersions.map((version) => (
                <VersionItem
                  elevation={2}
                  key={version.version}
                  onClick={() => handleVersionChange(version.version)}
                  selected={selectedVersion === version.version}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" style={{ display: 'inline-block' }}>
                      Versjon {version.version}{' '}
                      {version.version === collabHistory?.currentVersion && (
                        <Typography variant="body2" style={{ display: 'inline-block' }}>
                          (gjeldende)
                        </Typography>
                      )}
                    </Typography>
                    <Typography variant="body2" style={{ display: 'inline-block' }}>
                      {formatDate(new Date(version.date))}
                    </Typography>
                  </Stack>

                  <Typography variant="body2">{version.name}</Typography>
                  <Stack justifyContent="flex-end" direction="row"></Stack>
                </VersionItem>
              ))}
            </StyledSidebarItems>
            <Stack gap={1}>
              <Button
                startIcon={<Restore />}
                disabled={selectedVersion === collabHistory?.currentVersion || isCreatingNewContentVersion}
                onClick={() => handleRevertToVersion(selectedVersion)}
                size="medium"
                variant="contained">
                Gjenopprett versjon {selectedVersion}
              </Button>
            </Stack>
          </StyledSidebarContainer>
        </StyledLayout>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleOnClose}>
          Avbryt
        </Button>
      </DialogActions>
    </Dialog>
  );
};
function useScrollPrevention(modal: Modal<HistoryDialogProps, HistoryDialogResult>) {
  useEffect(() => {
    if (modal.isOpen) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = originalStyle;
      };
    }
  }, [modal.isOpen]);
}
