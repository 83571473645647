import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { NumberedNodes, useNumberedNodes, useUpdateNumberedNodesOnEditor } from '../../tiptap/numbering';
import { Stack } from '@mui/material';
import { TocItem, useTableOfContents } from './useTableOfContents';
import { Active, DndContext, DragEndEvent, Over, useDraggable, useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator, Toc } from '@mui/icons-material';
import styled from '@emotion/styled';
import { SidebarHeading } from '../SidebarHeading';
import { useActiveHeadingObserver } from './useActiveHeadingObserver';
import { useGuideCmsContext } from '../../GuideCmsContext';
import { scrollElementIntoView } from '../../navigate';

const StyledTocContainer = styled.div`
  padding: 16px;
  height: 100%;
  padding-bottom: 100px;
  overflow-y: scroll;
`;

export const TableOfContents: FC<{ editor: Editor }> = ({ editor }) => {
  const tableOfContents = useTableOfContents();
  const numberedNodes = useNumberedNodes(editor);
  const { forceRefreshEditors } = useGuideCmsContext();
  const updateNumberedNodesOnEditor = useUpdateNumberedNodesOnEditor();
  const { activeNumbering } = useActiveHeadingObserver();

  const moveHeadingToIndex = (fromIndex: number, toIndex: number) => {
    const nodes = editor.getJSON().content ?? [];
    const fromNode = nodes[fromIndex];
    let numberOfNodesToMoveWithHeading = 1;
    for (let i = fromIndex + 1; i < nodes.length; i++) {
      if (nodes[i].type === 'heading' && nodes[i].attrs?.level <= fromNode.attrs?.level) {
        break;
      }
      numberOfNodesToMoveWithHeading++;
    }
    moveItemsInArray(nodes, fromIndex, toIndex, numberOfNodesToMoveWithHeading);

    editor.commands.setContent({ type: 'doc', content: nodes });
    forceRefreshEditors();
    updateNumberedNodesOnEditor(editor);
  };

  const moveItemsInArray = (array: unknown[], fromIndex: number, toIndex: number, numberOfItemsToMove: number) => {
    const itemsToMove = array.splice(fromIndex, numberOfItemsToMove);
    if (toIndex > fromIndex) {
      toIndex -= numberOfItemsToMove;
    }
    array.splice(toIndex, 0, ...itemsToMove);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!active || !over) return;
    if (!isDropAllowed(active, over)) return;

    const jsonContent = editor.getJSON().content;
    if (!jsonContent) return;

    const fromIndex = jsonContent.findIndex((node) => node.attrs?.id === active.id);
    let toIndex: number = -1;
    if (over.id === 'end') {
      toIndex = jsonContent.length;
    } else {
      toIndex = jsonContent.findIndex((node) => node.attrs?.id === over.id);
    }
    moveHeadingToIndex(fromIndex, toIndex);
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <StyledTocContainer>
        <SidebarHeading icon={<Toc />}>Innholdsfortegnelse</SidebarHeading>

        {tableOfContents.map((heading) => (
          <div key={heading.id}>
            <DraggableItem
              activeNumbering={activeNumbering}
              numberedNodes={numberedNodes}
              item={heading}
              indentation={heading.level - 1}
              isEditable={editor.isEditable}>
              {heading.items?.map((subHeading) => (
                <DraggableItem
                  key={subHeading.id}
                  activeNumbering={activeNumbering}
                  numberedNodes={numberedNodes}
                  indentation={subHeading.level - 1}
                  item={subHeading}
                  isEditable={editor.isEditable}>
                  {subHeading.items?.map((subSubHeading) => (
                    <DraggableItem
                      key={subSubHeading.id}
                      activeNumbering={activeNumbering}
                      numberedNodes={numberedNodes}
                      indentation={1}
                      item={subSubHeading}
                      isEditable={editor.isEditable}></DraggableItem>
                  ))}
                </DraggableItem>
              ))}
            </DraggableItem>
          </div>
        ))}
        <DroppableItem id="end" level={1} />
      </StyledTocContainer>
    </DndContext>
  );
};

const DraggableItem: FC<{
  numberedNodes: NumberedNodes;
  item: TocItem;
  indentation: number;
  isEditable: boolean;
  activeNumbering: string;
  children?: React.ReactNode;
}> = ({ numberedNodes, item, indentation, isEditable, activeNumbering, children }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: item.id,
    disabled: !isEditable,
    data: { type: 'heading', level: item.level },
  });
  const numbering = numberedNodes.get(item.id)?.numbering;
  const isActive = numbering === activeNumbering;
  const style = {
    transform: CSS.Translate.toString(transform),
    paddingLeft: `${16 * indentation}px`,
    fontWeight: isActive ? 'bold' : 'normal',
  };

  return (
    <div>
      <div ref={setNodeRef} style={style}>
        <DroppableItem id={item.id} level={item.level} />
        <Stack direction="row">
          {isEditable && (
            <div title="Dra for å flytte" {...listeners} {...attributes} style={{ cursor: isDragging ? 'grabbing' : 'grab' }}>
              <DragIndicator color="action" />
            </div>
          )}

          <div
            style={{ cursor: 'pointer', overflowWrap: 'anywhere' }}
            title={`Gå til punkt ${numbering}`}
            onClick={() => {
              scrollElementIntoView(`i${numbering}`);
            }}>
            {numbering} {item.text}
          </div>
        </Stack>

        {children}
      </div>
    </div>
  );
};

const DroppableItem: FC<{ id: string; level: number }> = ({ id, level }) => {
  const { setNodeRef, isOver, active, over } = useDroppable({ id, data: { type: 'heading', level } });
  const isAllowed = isDropAllowed(active, over);
  const isDragging = active?.id === id;
  let color = 'transparent';
  if (isOver && !isDragging && isAllowed) {
    color = '#ccc';
  }
  return <div ref={setNodeRef} style={{ height: '2px', backgroundColor: color }}></div>;
};

const isDropAllowed = (active: Active | null, over: Over | null) => {
  if (!active || !over) return false;
  return active?.data.current?.type === 'heading' && active?.data.current?.level >= over?.data.current?.level;
};
