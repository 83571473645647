import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Add, Edit } from '@mui/icons-material';
import { StyledPaper } from '../../theme';
import { Guide } from '../Details';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_NEW_GUIDE_CONTENT_DOCUMENT } from './content.graphql';
import { formatDate } from '../../Formatters';
import { ConfirmDialog, ConfirmDialogProps } from '../../dialogs/ConfirmDialog';
import { useModal } from '../../dialogs/useModal';

export const InfoBox: FC<{ guide: Guide }> = ({ guide }) => {
  const navigate = useNavigate();
  const [createNewContentDocument, { loading: isCreatingNewContentDocument }] = useMutation(CREATE_NEW_GUIDE_CONTENT_DOCUMENT);
  const createNewDocumentConfirmModal = useModal<ConfirmDialogProps, boolean>({
    data: {
      title: 'Er du sikker?',
      message: (
        <>
          <Box>Hvis du fortsetter vil du miste tilgangen til eksisterende innhold og versjonshistorikk.</Box>
          <Box sx={{ mt: 2 }}>Dette er noe man vanligvis bare gjør ved starten av en fullstendig revisjon av innholdet.</Box>
        </>
      ),
      submitText: 'Start på nytt med blanke ark',
    },
  });

  const handleCreateNewCmsContentDocument = async () => {
    var confirmed = await createNewDocumentConfirmModal.open();
    if (!confirmed) return;

    await createNewContentDocument({ variables: { input: { id: guide.id } } });
    navigate(`/guide-editor/${guide.id}/edit`);
  };

  const showCreateNewDocumentButton = !!guide.tiptapDocument && guide.tiptapDocument.documentVersion > -1;

  return (
    <StyledPaper>
      <Typography variant="h6" component="h3">
        Innhold
      </Typography>
      <Typography variant="body1">Dokument opprettet {formatDate(guide.tiptapDocument?.createdAt)}</Typography>
      <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
        <Button variant="contained" component={Link} to={`/guide-editor/${guide.id}/edit`} startIcon={<Edit />}>
          Endre i CMS
        </Button>

        {showCreateNewDocumentButton && (
          <Button variant="outlined" disabled={isCreatingNewContentDocument} onClick={handleCreateNewCmsContentDocument} startIcon={<Add />}>
            Start på nytt med blanke ark
          </Button>
        )}
      </Stack>
      {createNewDocumentConfirmModal.isOpen && <ConfirmDialog modal={createNewDocumentConfirmModal} />}
    </StyledPaper>
  );
};
