import React, { FC } from 'react';
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { GuideLinkExtensionRenderOptions } from '../plugins/GuideLink';
import { LibraryBooks } from '@mui/icons-material';

const renderText = (node: ProseMirrorNode) => {
  return node.attrs.docName;
};

export const GuideLinkView: FC<NodeViewProps> = (props) => {
  return (
    <NodeViewWrapper className="react-component" as={'span'}>
      <span className="link link-guide" title="Lenke til anvisning">
        <span className="link-icon">
          <LibraryBooks />
        </span>{' '}
        {props.node.attrs.docName}
      </span>
    </NodeViewWrapper>
  );
};

export const getGuideLinkExtensionRenderOptions = (): GuideLinkExtensionRenderOptions => {
  return {
    viewComponent: GuideLinkView,
    renderText: (node) => renderText(node),
  };
};
