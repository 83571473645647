import { gql } from '../../__generated__';

export const GUIDE_CMS_INFO = gql(/* GraphQL */ `
  query GetGuideCmsInfo($id: UUID!) {
    guide(id: $id) {
      id
      docId
      docName
      docNumber
      docTitle
      tiptapDocument {
        documentName
        documentVersion
      }
    }
  }
`);

export const CREATE_NEW_GUIDE_CONTENT_VERSION = gql(/* GraphQL */ `
  mutation GuideCreateNewContentVersion($input: GuideCreateNewContentVersionInput!) {
    guideCreateNewContentVersion(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        tiptapDocument {
          documentName
          documentVersion
        }
        guideLinks {
          id
        }
        standards {
          id
        }
      }
    }
  }
`);

export const CREATE_NEW_GUIDE_CONTENT_DOCUMENT = gql(/* GraphQL */ `
  mutation GuideCreateNewContentDocument($input: GuideCreateNewContentDocumentInput!) {
    guideCreateNewContentDocument(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        tiptapDocument {
          documentName
          documentVersion
        }
        guideLinks {
          id
        }
        standards {
          id
        }
      }
    }
  }
`);

export const IMPORT_LAST_PUBLISHED_VERSION = gql(/* GraphQL */ `
  mutation GuideImportLastPublishedVersion($input: GuideImportLastPublishedVersionInput!) {
    guideImportLastPublishedVersion(input: $input) {
      importLastPublishedVersionPayload {
        cmsContent
        guideModel {
          id
        }
      }
    }
  }
`);

export const UPLOAD_FIGURE = gql(/* GraphQL */ `
  mutation UploadGuideFigureFile($input: GuideUploadFigureFileInput!) {
    guideUploadFigureFile(input: $input) {
      figureFile {
        id
        figureId
        type
        metadata {
          name
          value
        }
        mimeType
        fileName
      }
    }
  }
`);
