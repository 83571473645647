import React, { FC } from 'react';
import { Modal } from '../../../dialogs/useModal';
import { GuideFiguresOverview } from './GuideFiguresOverview';
import { EditGuideFigure } from './EditGuideFigure';
import { Dialog } from '@mui/material';
import { FiguresContextProvider, useFiguresContext } from './FiguresContext';

export interface GuideFigureDialogResult {}

export interface GuideFigureDialogProps {
  editFigureId?: string;
}

export const GuideFigureDialog: FC<Modal<GuideFigureDialogProps, GuideFigureDialogResult>> = (modal) => {
  return (
    <FiguresContextProvider modal={modal}>
      <GuideFigureDialogInner modal={modal} />
    </FiguresContextProvider>
  );
};

const GuideFigureDialogInner: FC<{ modal: Modal<GuideFigureDialogProps, GuideFigureDialogResult> }> = ({ modal }) => {
  const { isEditing } = useFiguresContext();
  const handleOnClose = () => {
    modal.close();
  };
  return (
    <Dialog open={true} maxWidth="md" fullWidth={true} disableEscapeKeyDown={isEditing} onClose={handleOnClose}>
      {isEditing ? <EditGuideFigure /> : <GuideFiguresOverview />}
    </Dialog>
  );
};
