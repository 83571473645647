import React from 'react';
import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { GuideCmsContextProvider } from './GuideCmsContext';
import { CommentsContextProvider } from './sidebar/comments/CommentsContext';
import { Layout } from './Layout';
import { ThreadsContextProvider } from './sidebar/tiptapComments/context';

export const GuideCmsReadOnlyPage: FC = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const versionSearchParam = searchParams.get('version');
  const version = versionSearchParam ? parseInt(versionSearchParam) : undefined;

  if (!id) return null;
  return (
    <GuideCmsContextProvider guideId={id} version={version}>
      <CommentsContextProvider>
        <Layout isEditing={false} />
      </CommentsContextProvider>
    </GuideCmsContextProvider>
  );
};

export const GuideCmsEditPage: FC = () => {
  const { id } = useParams();

  if (!id) return null;
  return (
    <GuideCmsContextProvider guideId={id}>
      <ThreadsContextProvider>
        <CommentsContextProvider>
          <Layout isEditing={true} />
        </CommentsContextProvider>
      </ThreadsContextProvider>
    </GuideCmsContextProvider>
  );
};
