import React, { FC } from 'react';
import { NodeViewWrapper, NodeViewProps, NodeViewContent } from '@tiptap/react';
import styled from '@emotion/styled';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { GuideFigureDialog, GuideFigureDialogProps, GuideFigureDialogResult } from '../../../figures/GuideFigureDialog';
import { useModal } from '../../../../../dialogs/useModal';
import { getHtmlId, getTypeDescription, useNumberedNode } from '../../numbering';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import { BlockNode } from '../BlockNode';
import { theme } from '../../../../../theme';
import { ImageFigureAttributes } from '../plugins/ImageFigure';
import { getThumbnailUrlFromImageFigure, mapImageFigureFromAttributes } from '../../../figures/figureHelper';
import { useConfig } from '../../../../../env';
import { useGuideCmsContext } from '../../../GuideCmsContext';

const StyledToolbar = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 4px;
`;

const StyledFooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4px;
  align-items: end;
`;

const StyledPaper = styled(Paper)`
  display: inline-block;
  max-width: 800px;
  padding: ${theme.spacing(2)};
  margin: ${theme.spacing(1, 0)};
  & > img {
    margin-bottom: ${theme.spacing(2)};
    max-height: 300px;
  }
  & figcaption {
    position: relative;
    margin: 10px 0;
    padding: 5px 10px;
    border: 1px solid #bdbdbd;
    width: fit-content;
    min-width: 80px;

    &:before {
      font-size: 12px;
      color: #666;
      position: absolute;
      top: -10px;
      background-color: white;
      content: 'Figurtekst';
    }
  }
`;

export const ImageFigureView: FC<NodeViewProps> = (props) => {
  const attributes = props.node.attrs as ImageFigureAttributes;
  const config = useConfig();
  const { guideId } = useGuideCmsContext();

  const modal = useModal<GuideFigureDialogProps, GuideFigureDialogResult>({ data: {} });
  const node = useNumberedNode(attributes.id ?? '', props.editor);
  const imageFigure = mapImageFigureFromAttributes(attributes);
  const thumbnailUrl = getThumbnailUrlFromImageFigure(config, guideId, imageFigure);
  return (
    <>
      {modal.isOpen && <GuideFigureDialog {...modal} />}
      <NodeViewWrapper className="react-component">
        <BlockNode props={props}>
          <StyledPaper id={node ? getHtmlId(node) : ''} elevation={3}>
            <img src={thumbnailUrl} />
            <div>
              <Typography variant="body2" color="text.secondary">
                {imageFigure.files?.[0]?.fileName ?? ''}
              </Typography>
            </div>
            <StyledFooterGrid>
              <div>
                <Typography variant="body2" color="text.secondary">
                  {capitalizeFirstLetter(getTypeDescription('imageFigure'))} {node?.numbering}
                </Typography>
                <NodeViewContent className="content" as="div"></NodeViewContent>
              </div>
              <StyledToolbar>
                {props.editor.isEditable && (
                  <>
                    <Tooltip title="Rediger figur">
                      <IconButton
                        tabIndex={-1}
                        onClick={() => {
                          modal.open({ editFigureId: attributes.figureId ?? undefined });
                        }}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Fjern figur">
                      <IconButton
                        tabIndex={-1}
                        onClick={() => {
                          props.deleteNode();
                        }}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </StyledToolbar>
            </StyledFooterGrid>
          </StyledPaper>
        </BlockNode>
      </NodeViewWrapper>
    </>
  );
};
