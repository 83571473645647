import { FC, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Stack } from '@mui/material';
import React from 'react';
import { Modal } from '../../../dialogs/useModal';
import { useThreadsState } from '../sidebar/tiptapComments/context';
import { useGuideCmsContext } from '../GuideCmsContext';

export interface AddCommentDialogResult {}

export interface AddCommentDialogProps {}

export const AddCommentDialog: FC<Modal<AddCommentDialogProps, AddCommentDialogResult>> = (modal) => {
  const { createThread } = useThreadsState();
  const { isSidebarOpen, toggleSidebar, setSidebarType } = useGuideCmsContext();

  const [comment, setComment] = useState('');
  const { close } = modal;
  const handleOnClose = () => {
    close();
  };
  const handleOnUpdate = async () => {
    createThread(comment);
    if (!isSidebarOpen) {
      setSidebarType('comments');
      toggleSidebar();
    }
    close();
  };

  return (
    <Dialog open={true} onClose={handleOnClose} fullWidth={true}>
      <DialogTitle>Legg til kommentar</DialogTitle>
      <DialogContent>
        <Stack>
          <TextField multiline minRows={4} fullWidth value={comment} onChange={(e) => setComment(e.target.value)} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
        <Button variant="contained" onClick={handleOnUpdate}>
          Lagre
        </Button>
      </DialogActions>
    </Dialog>
  );
};
