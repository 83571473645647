import React, { createContext, useCallback, useContext, ReactNode, useState, useEffect, useRef } from 'react';
import { useGuideCmsContext } from '../../GuideCmsContext';
import { hoverOffThread, hoverThread, subscribeToThreads, ThreadStorage } from '@tiptap-pro/extension-comments';
import { useAuth } from 'oidc-react';
import { CollaborationUser, createUserFromProfile } from '../../tiptapCollab/collaborationUser';
import { TCollabComment, TCollabThread } from '@hocuspocus/provider';
import { Editor } from '@tiptap/react';

interface ThreadData {
  user: CollaborationUser;
}

interface CommentData {
  user: CollaborationUser;
}

export type CommentType = TCollabComment<CommentData>;
export type ThreadType = TCollabThread<ThreadData, CommentData>;

interface OnClickThreadOptions {
  origin: 'sidebar' | 'editor';
}

interface ThreadsContextType {
  threads: ThreadType[];
  selectedThreads: ThreadType[];
  selectedThread: string | null;
  showUnresolved: boolean;
  setShowUnresolved: (showUnresolved: boolean) => void;
  createThread: (comment: string) => void;
  deleteThread: (threadId: string) => void;
  resolveThread: (threadId: string) => void;
  unresolveThread: (threadId: string) => void;
  createComment: (threadId: string, content: string) => void;
  canUpdateComment: (comment: CommentType) => boolean;
  updateComment: (threadId: string, commentId: string, content: string) => void;
  removeComment: (threadId: string, commentId: string) => void;
  onClickThread: (threadId: string | null, options?: OnClickThreadOptions) => void;
  onHoverThread: (threadId: string) => void;
  onLeaveThread: (threadId?: string) => void;
}

export const ThreadsContext = createContext<ThreadsContextType>({
  threads: [],
  selectedThreads: [],
  selectedThread: null,
  showUnresolved: true,
  setShowUnresolved: () => {},
  createThread: () => {},
  deleteThread: () => {},
  resolveThread: () => {},
  unresolveThread: () => {},
  createComment: () => {},
  canUpdateComment: () => false,
  updateComment: () => {},
  removeComment: () => {},
  onClickThread: () => {},
  onHoverThread: () => {},
  onLeaveThread: () => {},
});

interface ThreadsProviderProps {
  children: ReactNode;
}

export const ThreadsContextProvider = ({ children }: ThreadsProviderProps) => {
  const { editor, provider } = useGuideCmsContext();
  const auth = useAuth();
  const user = createUserFromProfile(auth.userData?.profile);
  const [threads, setThreads] = useState<TCollabThread[]>([]);
  const [selectedThread, setSelectedThread] = useState<string | null>(null);
  const [showUnresolved, setShowUnresolved] = useState<boolean>(true);

  // Add a ref to track the current editor instance
  const editorRef = useRef<Editor | null>(null);
  useEffect(() => {
    editorRef.current = editor;
  }, [editor]);

  const storage = editor?.storage.comments as ThreadStorage;
  const selectedThreads = storage?.focusedThreads ?? [];

  useEffect(() => {
    if (provider) {
      const unsubscribe = subscribeToThreads({
        provider,
        callback: (currentThreads) => {
          setThreads(currentThreads);
        },
      });

      return () => {
        unsubscribe();
      };
    }
  }, [provider]);

  const createThread = useCallback(
    (comment: string) => {
      if (!comment || !editor) {
        return;
      }
      editor.chain().focus().setThread({ content: comment, data: { user }, commentData: { user } }).run();
    },
    [editor, user],
  );

  const deleteThread = useCallback(
    (threadId: string) => {
      provider?.deleteThread(threadId);
      editor?.commands.removeThread({ id: threadId });
    },
    [editor],
  );

  const resolveThread = useCallback(
    (threadId: string) => {
      editor?.commands.resolveThread({ id: threadId });
    },
    [editor],
  );

  const unresolveThread = useCallback(
    (threadId: string) => {
      editor?.commands.unresolveThread({ id: threadId });
    },
    [editor],
  );

  const createComment = useCallback(
    (threadId: string, content: string) => {
      editor?.commands.createComment({
        threadId,
        content,
        data: { user },
      });
    },
    [editor],
  );

  const updateComment = useCallback(
    (threadId: string, commentId: string, content: string) => {
      editor?.commands.updateComment({
        threadId,
        id: commentId,
        content,
        data: { user },
      });
    },
    [editor],
  );

  const removeComment = useCallback(
    (threadId: string, commentId: string) => {
      editor?.commands.removeComment({
        threadId,
        id: commentId,
      });
    },
    [editor],
  );

  const onHoverThread = useCallback(
    (threadId: string) => {
      if (!editor) return;
      hoverThread(editor, [threadId] as any[]); // Tiptap uses a different type for threadId, but it is a GUID
    },
    [editor],
  );

  const onLeaveThread = useCallback(() => {
    if (!editor) return;
    hoverOffThread(editor);
  }, [editor]);

  // This function is called when a thread is clicked. It is added to the comments extension.
  // Due to the order things are initialized, we need to use the editorRef so that the extension uses the current editor instance.
  const onClickThread = useCallback(
    (threadId: string | null, options: OnClickThreadOptions = { origin: 'editor' }) => {
      if (!editorRef.current) return;
      const isResolved = !!threads.find((t) => t.id === threadId)?.resolvedAt;
      if (!threadId || isResolved) {
        setSelectedThread(null);
        editorRef.current.commands.unselectThread();
        return;
      }
      setSelectedThread(threadId);
      editorRef.current.commands.selectThread({ id: threadId, focus: false });
      if (options?.origin === 'editor') {
        const element = document.getElementById('thread-' + threadId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
      if (options?.origin === 'sidebar') {
        const element = document.querySelector(`[data-thread-id="${threadId}"]`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    [provider, threads, setSelectedThread],
  );

  const canUpdateComment = (comment: CommentType) => {
    return comment.data.user.id === user.id;
  };

  const providerValue: ThreadsContextType = {
    threads,
    selectedThreads,
    selectedThread,
    showUnresolved,
    setShowUnresolved,
    createThread,
    deleteThread,
    resolveThread,
    unresolveThread,
    createComment,
    canUpdateComment,
    updateComment,
    removeComment,
    onHoverThread,
    onLeaveThread,
    onClickThread,
  };

  return <ThreadsContext.Provider value={providerValue}>{children}</ThreadsContext.Provider>;
};

export const useThreadsState = () => {
  return useContext(ThreadsContext);
};
